import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash'

import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils'
import { station as stationSelector, electronicInvoicing } from '../../../selectors/app'
import { country as countrySelector, companySelector } from '../../../selectors/company'
import { currency, numeration as numerationSelector } from '../../../selectors/activeInvoice'
import { removeItem, increaseItem, decreaseItem } from '../../../reducers/activeInvoice';
import { openSideModal } from '../../../reducers/sideModals';
import { renderErrors } from './utils';

import { Icon } from '@alegradev/smile-ui-react';
import useDecimalsVersionsGroup from '../../../hooks/useDecimalsVersionsGroup/hook';
import { Tooltip } from '@alegradev/smile-ui-react';
import { priceList } from '../../../selectors/activeInvoice';
import { useFormat } from '../../../hooks/useFormat';
import { IconMinus, IconPencil, IconPlus, IconTag, IconTrash } from '@tabler/icons-react';

const Item = ({ item, index }) => {
  const itemRef = useRef();
  const dispatch = useDispatch();
  const station = useSelector(stationSelector);
  const company = useSelector(companySelector);
  const selectedCurrency = useSelector(currency);
  const country = useSelector(countrySelector);
  const numeration = useSelector(numerationSelector);
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const invoicePriceList = useSelector(priceList)
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const { decimal, fmt } = useFormat(selectedCurrency);

  useEffect(() => {
    itemRef.current.scrollIntoView({
      behavior: 'smooth', block: 'nearest'
    });
  }, [])

  if (!item)
    return null;

  const itemValues = useMemo(() => {
    const copyItem = { ...item, currentPriceList: invoicePriceList }

    if(isDecimalActive) {
      return calculateSingleItemValuesWithDecimals(copyItem, decimal, false, country)
    }

    return calculateSingleItemValues(copyItem, decimal)
  }, [item, decimal, country, company, isDecimalActive]);

  const decreaseQty = (e, quantity) => {
    e.stopPropagation();
    if (quantity > 1)
      dispatch(decreaseItem(index))
    if (quantity === 1)
      dispatch(removeItem(index))
  }

  const increaseQty = (e) => {
    e.stopPropagation();
    dispatch(increaseItem(index))
  }

  const openEditModal = () => {
    dispatch(openSideModal({ sideModal: 'editItem', params: { index } }))
  }

  return (
    <div style={{ background: "white" }} className="d-flex position-relative pointer new-active-invoice__item-container" onClick={openEditModal}>
      <div className="new-active-invoice__item w-100 d-flex d-sm-none flex-column py-3 pl-3" ref={itemRef}>
        {smallDevices({ item, openEditModal, decreaseQty, increaseQty, itemValues, decimal, fmt })}
        {renderErrors({ item, country, station, isElectronic })}
      </div>
      <div className="new-active-invoice__item w-100 d-none d-sm-flex flex-column py-3 pl-3" ref={itemRef}>
        <div className="new-active-invoice__item-actions">
          <Tooltip
            placement="left"
            overlay="Editar en esta venta">
            <div className="new-active-invoice__item-actions-button first d-flex justify-content-center"
              onClick={openEditModal}>
              <Icon icon={IconPencil} />
            </div>
          </Tooltip>
          <Tooltip
            placement="left"
            overlay="Quitar">
            <div className="new-active-invoice__item-actions-button d-flex justify-content-center"
              onClick={event => {
                event.stopPropagation();
                dispatch(removeItem(index))
              }}>
              <Icon icon={IconTrash} />
            </div>
          </Tooltip>
        </div>

        <div className="new-active-invoice__item-info d-flex align-items-center justify-content-between px-3 py-1">
          <div className="d-flex flex-column text-break text-truncate text-nowrap new-active-invoice__item-data">
            <div className="new-active-invoice__item-name name-large text-truncate">{get(item, 'name')}</div>
            <div className="d-flex text-break text-truncate text-nowrap">
              <div className="new-active-invoice__item-price">
                {itemValues.total.dividedBy(itemValues.quantity).toFormat(decimal, fmt)}
              </div>
              <div className="new-active-invoice__item-reference ml-2">
                {!!get(item, 'reference.reference')
                  ? " | " + get(item, 'reference.reference') : !!get(item, 'reference')
                    ? " | " + get(item, 'reference') : ''
                }
              </div>
            </div>
          </div>

          <div style={{ width: "106px", height: "38px" }} />

          <div className="d-flex flex-column align-items-end new-active-invoice__item-total-price">
            <div data-testid="item-selected-total" className="text-right text-truncate text-nowrap">
              {itemValues.total.toFormat(decimal, fmt)}
            </div>

            {!!itemValues.discountValue && itemValues.discountValue.gt(0) && (
              <div className="text-right text-truncate text-nowrap text-muted discount">
                -{itemValues.discountValue.toFormat(decimal, fmt)}
              </div>
            )}
          </div>
        </div>

        <div className="px-3">
          {renderErrors({ item: {...item,  previousQuantity: item?.quantity}, country, station, isElectronic })}
        </div>
      </div>

      <div className="d-none d-sm-flex new-active-invoice__item-quantity-container align-items-center justify-content-center">
        <div
          className="d-flex justify-content-center align-items-center pointer"
          onClick={(e) => decreaseQty(e, itemValues.quantity)}
        >
          <Icon icon={IconMinus} extraClass="icon-gray" />
        </div>
        <div className="d-flex justify-content-center align-items-center new-active-invoice__item-quantity">
          {itemValues.quantity}
        </div>
        <div
          className="d-flex justify-content-center align-items-center pointer"
          onClick={increaseQty}
        >
          <Icon icon={IconPlus} extraClass="icon-gray" />
        </div>
      </div>
    </div>
  )
}

const smallDevices = ({ item, increaseQty, decreaseQty, openEditModal, itemValues, decimal, fmt }) => (
  <div className="new-active-invoice__item-info d-flex align-items-center justify-content-between px-2 py-1" onClick={openEditModal}>
    <div className="new-active-invoice__item-image-container d-flex align-items-center">
      {item.images
        ? <img src={item.images[0].url} alt={item.name} />
        : <Icon icon={IconTag} extraClass="icon-gray icon x2" />}
    </div>

    <div className="d-flex flex-column text-break text-truncate text-nowrap pl-4 new-active-invoice__item-data">
      <div className="new-active-invoice__item-name text-truncate">{get(item, 'name')}</div>

      <div className="d-flex d-sm-none align-items-center">
        <div className="d-flex justify-content-center align-items-center pointer"
          onClick={e => decreaseQty(e, itemValues.quantity)}>
          <Icon icon={IconMinus} extraClass="icon-gray" />
        </div>
        <div className="d-flex justify-content-center align-items-center new-active-invoice__item-quantity">
          {itemValues.quantity}
        </div>
        <div className="d-flex justify-content-center align-items-center pointer"
          onClick={increaseQty}>
          <Icon icon={IconPlus} extraClass="icon-gray" />
        </div>
      </div>
    </div>

    <div className="d-flex flex-column align-items-end new-active-invoice__item-total-price">
      <div className="text-right text-truncate text-nowrap">
        {itemValues.total.toFormat(decimal, fmt)}
      </div>

      {!!itemValues.discountValue && itemValues.discountValue.gt(0) && (
        <div className="text-right text-truncate text-nowrap text-muted discount">
          -{itemValues.discountValue.toFormat(decimal, fmt)}
        </div>
      )}
    </div>
  </div>
)

Item.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.any.isRequired
}

export default Item;

