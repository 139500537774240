import React from 'react'
import PropTypes from 'prop-types';

import Methods from './RefundMethods'
import Form from '../../../forms/newRefund'
import { LastStepForm } from '../../../forms/newRefund/lastStepForm';
import ItemsForm from '../../../forms/newRefund/ItemsForm';

const Payments = ({ step, method, onChooseMethod, changeStep, reset, openEditItem, openEditTip }) => {
  return (
    <div className="d-flex flex-column modal__refund-body">
      {step === 0 && (
        <Methods onChooseMethod={onChooseMethod} />
      )}
      {(step === 1 || step === 2) && (
        <Form reset={reset} method={method} onChooseMethod={onChooseMethod} changeStep={changeStep} />
      )}
      {step === 3 && (
        <ItemsForm 
          changeStep={changeStep} 
          openEditItem={openEditItem}
          openEditTip={openEditTip}
        />
      )}
      {step === 4 && (
        <LastStepForm method={method} />
      )}
    </div>
  )
}

Payments.propTypes = {
  step: PropTypes.number,
  method: PropTypes.string,
  onChooseMethod: PropTypes.func
}

export default Payments;