import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType } from '../../hooks/useDeviceType';

import { setNotificationContent } from '../../reducers/notificationsWC';
import { WebComponents, webcomponentHasContent } from '../../selectors/notificationsWC';

const NotificationTop = ({ userId }) => {
  const [styling, setStyling] = useState({})
  const notificationBoxHasContent = useSelector(webcomponentHasContent(WebComponents.componentBox))
  const sizeType = useDeviceType();
  const dispatch = useDispatch()
  let ref = useRef();

  useEffect(() => {
    notificationBoxHasContent
    if (notificationBoxHasContent && sizeType === "mobile")
      setStyling({ position: 'absolute', top: '0', zIndex: -1, width: '100vw' })
    else
      setStyling({})
  }, [notificationBoxHasContent, sizeType])

  useEffect(() => {
    const checkContent = () => {
      const hasContent =
        !!ref.current?.shadowRoot.querySelector('.notification-content') &&
        !!ref.current?.shadowRoot.querySelector('.notification-content').innerText;
      dispatch(setNotificationContent({ webcomponent: WebComponents.componentTop, hasContent }))
    };
    checkContent();

    const observer = new MutationObserver(checkContent);
    const targetNode = ref.current?.shadowRoot;

    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => {
      if (targetNode) {
        observer.disconnect();
      }
    };
  }, [ref, dispatch])

  return (
    <div style={styling}>
      <notifications-top-box
        app_env={process.env.REACT_APP_BRANCH === "master" ? "production" : "testing"}
        application="alegra-pos"
        user_id={userId}
        section="index-pos-home"
        ref={ref}
      />
    </div>
  )
}

export default NotificationTop