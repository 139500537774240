import { Card, PageHeading, Space } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import PosElectronicActivationIllustration from '../../assets/images/illustrations/electronic-pos-activation.png';
import useElectronicPayrollStatus from '../../hooks/useElectronicPayrollStatus/hook';
import { useSelector } from 'react-redux';
import { isElectronicPOSDocumentAvailableSelector } from '../../selectors/numerations';

function ElectronicDocuments() {
  const history = useHistory();
  const { isActive } = useElectronicPayrollStatus();
  const isPosElectronicActive = useSelector(
    isElectronicPOSDocumentAvailableSelector
  );

  const isAnyActive = isActive || isPosElectronicActive;

  return (
    <div className='container-xl pt-5'>
      <div className='electronic-documents'>
        <div className='electronic-documents__info'>
          <PageHeading
            back={{
              title: I18n.get('', 'Ir a Facturar'),
              action: () => history.push('/'),
            }}
            title={I18n.get(
              '',
              'Emite documentos POS o facturas electrónicas, ¡tú decides! ✌'
            )}
            titleVariant='heading-2'
            description={I18n.get(
              '',
              `Puedes elegir si usar uno de estos documentos o ambos para registrar tus ventas y cumplir con todos los requisitos de la DIAN.`
            )}
          />

          <Space height={12} />

          <Card
            title={I18n.get('', 'Factura electrónica')}
            description={I18n.get(
              '',
              'Es el documento más usado en Colombia y sirve para registrar todo tipo de ventas, sean de contado o a crédito.'
            )}
            descriptionExtra={{
              label: 'Saber más',
              onClick: () =>
                window.open(
                  'https://ayuda.alegra.com/es/agiliza-la-facturaci%C3%B3n-del-terminal-de-tu-punto-de-venta-pos-colombia',
                  '_blank'
                ),
            }}
            tag={{
              label: isActive
                ? I18n.get('activated', 'Activada')
                : I18n.get('', ''),
              type: isActive ? 'primary' : 'indigo',
            }}
            action={
              !isActive
                ? {
                    label: 'Habilitar facturación electrónica',
                    onClick: () =>
                      window.open('https://mi.alegra.com/fe-wizard', '_blank'),
                  }
                : undefined
            }
          />
          <Space height={16} />
          <Card
            title={I18n.get('', 'Documento POS electrónico')}
            description={I18n.get(
              '',
              'Funciona igual que una factura electrónica con la diferencia de que solo puedes usarlo para ventas con pago de contado.'
            )}
            descriptionExtra={{
              label: 'Saber más',
              onClick: () =>
                window.open(
                  'https://ayuda.alegra.com/es/emite-documentos-equivalentes-pos-electronicos-desde-tu-cuenta-de-alegra-pos',
                  '_blank'
                ),
            }}
            tag={
              isPosElectronicActive
                ? {
                    label: I18n.get('', 'Activado'),
                    type: 'primary',
                  }
                : undefined
            }
            action={
              !isPosElectronicActive
                ? {
                    label: 'Habilitar POS electrónico',
                    onClick: () =>
                      window.open('https://mi.alegra.com/pos-wizard', '_blank'),
                  }
                : undefined
            }
          />
        </div>
        <div className='electronic-documents__illustration'>
          <img
            src={PosElectronicActivationIllustration}
            alt='pos electronic activation illustration'
          />
        </div>
      </div>
    </div>
  );
}

export default ElectronicDocuments;
