import React, { useEffect } from 'react'
import { I18n } from 'aws-amplify';
import { Field } from 'react-final-form';
import { capitalize, get } from 'lodash';
import { renderField, renderSelect } from '../../forms/fields/V0/Fields';
import Label from '../../common/Label';
import spainProvinces from '../../countriesData/spain/provinces.json';
import spainCities from '../../countriesData/spain/municipalityProvince.json';

const CompanySettings = (props) => {
  const companyIsElectronic = get(props, 'values.settings.electronicInvoicing', false);
  const province = get(props, 'values.address.province.key', '');
  const getSpainCities = () => {
    if(province){
      return spainCities.filter((item) => {
        const [, secondParam] = item.key.split(', ');
        return secondParam === province;
      });
    }
    
    return [];
  }
  useEffect(() => {
    if(!province){
      props.form.change('address.city', null)

    }
  },[province])
  return (
    <div className="w-100">
      <div className="form-row" style={{ margin: "0 -1.5rem" }}>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('identification', 'identificación')}
            required={companyIsElectronic} />
          <Field
            name="identification"
            component={renderField}
            data-testid="client-id-input"
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('taxName', 'Nombre')}
            required={true} />
          <Field
            name="firstName"
            component={renderField}
            data-testid="client-name-input"
            type="text"
            required
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('tradeName', 'Nombre')}
            required={companyIsElectronic} />
          <Field
            name="tradeName"
            component={renderField}
            data-testid="client-name-input"
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('address', 'Dirección')}
            required={companyIsElectronic} />
          <Field
            name="address.address"
            component={renderField}
            type="text"
            required={companyIsElectronic}
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={capitalize(I18n.get('province', 'provincia'))}
            required={companyIsElectronic} />
          <Field
            name="address.province"
            component={renderSelect}
            showClearIndicator
            options={spainProvinces}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            isSearchable={true}
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={capitalize(I18n.get('city', 'ciudad'))}
            required={companyIsElectronic} />
          <Field
            name="address.city"
            component={renderSelect}
            showClearIndicator
            options={getSpainCities()}
            getOptionLabel={(option) => option.municipality}
            getOptionValue={(option) => option.municipality}
            isSearchable={true}
            disabled={!province}
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('electronicMail', 'Correo electrónico')} />
          <Field
            name="email"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('phone', 'Teléfono')} />
          <Field
            name="phone"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('zipCode', 'Código postal')} />
          <Field
            name="address.zipCode"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('website', 'Sitio web')} />
          <Field
            name="website"
            component={renderField}
            type="text"
          />
        </div>
      </div>
    </div>
  )
}


export default CompanySettings