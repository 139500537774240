import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Field } from "react-final-form";
import { I18n } from "aws-amplify/lib-esm";
import { clear } from "../../../reducers/activeRefund";
import { Cash } from "./methods/Cash";
import { Combined } from "./methods/Combined";
import { CreditToSales } from "./methods/CreditToSales";
import { PositiveBalance } from "./methods/PositiveBalance";
import { renderSelect } from "../fields/V0/Fields";

const OPTIONS_METHODS = [
  {
    value: 'cash',
    label: I18n.get('refundCash', 'Devolución de dinero'),
  },
  {
    value: 'creditToSales',
    label: I18n.get('creditToSales', 'Crédito a ventas'),
  },
  {
    value: 'combined',
    label: I18n.get('combined', 'Combinado'),
  },
  {
    value: 'positiveBalance',
    label: I18n.get('positiveBalance', 'Saldo a favor '),
  }
];

const FormNewRefund = ({ method, onChooseMethod, changeStep, reset }) => {
  const dispatch = useDispatch();
  const defaultValue = useMemo(() => {
    // reset();
    return {
      value: OPTIONS_METHODS.filter(item => item.value === method)[0].value,
      label: OPTIONS_METHODS.filter(item => item.value === method)[0].label,
    }
  }, [method]);

  return (
    <div className="h-100 mt-4">
      <div className="refund-form-body">
        <Field
          defaultValue={defaultValue}
          options={OPTIONS_METHODS}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          onChange={option => {
            onChooseMethod(option.value);
            reset();
            dispatch(clear());
            return option;
          }}
          type="select"
          name="refund.method"
          component={renderSelect}
          className="col-12 p-0 select-refund new-refund-input"
          required
          label={I18n.get('typeRefund', 'Tipo de devolución')}
        />

        {method === 'cash' && (
          <Cash changeStep={changeStep} />
        )}

        {method === 'creditToSales' && (
          <CreditToSales changeStep={changeStep} />
        )}

        {method === 'combined' && (
          <Combined changeStep={changeStep} />
        )}

        {method === 'positiveBalance' && (
          <PositiveBalance changeStep={changeStep} />
        )}
      </div>
    </div>
  )
}

export default FormNewRefund