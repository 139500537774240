import React from 'react';
import QRCode from 'qrcode.react';

const renderQR = (qrCode) => {
  if (!qrCode)
    return null
  return (
    <QRCode
      value={qrCode.split('QRCode: ')[1]}
      renderAs='svg'
      size={120}
      level="M"
    />
  )
}

const Electronic = ({ setting, refund }) => {
  const { align } = setting;
  const { stamp, barCodeContent } = refund;

  let code = barCodeContent;

  const shouldShowQR = !!code && !!stamp

  if (!shouldShowQR) return null;

  return (
    <div className={`w-100 text-${align} py-2 d-flex flex-column align-items-center qr-info-panama`}>
      {renderQR(code)}
    </div>
  )
}

export default Electronic;