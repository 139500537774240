import React from 'react'
import PropTypes from 'prop-types'; 

const Detail = ({ 
  className, 
  header, 
  children, 
  selected, 
  loading, 
  error, 
  options, 
  emptyElement, 
  noSelectedElement,
  notAllowedToView 
}) => {
  if (!!notAllowedToView) 
    return <div className="master-detail__detail">{notAllowedToView}</div>
  
  if (!!error || !!loading)
    return null;

  if (!!options && !!options.length && !selected) 
    return <div className="master-detail__detail">{noSelectedElement}</div>
  
  if (!!options && !options.length) 
    return <div className="master-detail__detail">{emptyElement}</div>
  
  return (
    <div className={`master-detail__detail ${className ? className : ''}`}>
      <div className="detail-header">
        {header}
      </div>
      <div className="detail-body">
        {children}
      </div>
    </div>
  )
}

Detail.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  header: PropTypes.element,
  children: PropTypes.element,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  emptyElement: PropTypes.element,
  noSelectedElement: PropTypes.element,
  notAllowedToView: PropTypes.string
}

export default Detail;

