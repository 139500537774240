import { refundsDatesGroups } from "../../selectors/deployGroups";

export const legalStatusToColor = (status) => {
  switch (status) {
    case 'legalStatus.issued':
      return 'closed';
    case 'legalStatus.rejected':
      return 'open';
    case 'legalStatus.toBeIssued':
      return 'void';
    case 'legalStatus.inProcess':
      return 'process';
    default:
      return 'void';
  }
}

export const isUserElegibleForNewRefund = (country, company) => {
  if (!!['colombia'].includes(country))
    return true;
}