import { useSelector } from 'react-redux';
import { getShift } from '../../graphql/queries';
import { APIGraphqlSelector } from '../../selectors/app';
import { graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';

const fetchShift = async (APIGraphql, id) => {
  const response = await APIGraphql(graphqlOperation(getShift, { id }));

  return response?.data?.getShift;
};

export const useShiftQuery = (id) => {
  const APIGraphql = useSelector(APIGraphqlSelector);

  return useQuery({
    queryKey: ['shift', { id }],
    queryFn: () => fetchShift(APIGraphql, id),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: 1000 * 60 * 60 * 24,
  });
};