import { createSlice, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import store from 'store2';
import Cookie from 'js-cookie';
import { find, get } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';

import * as mutations from '../graphql/mutations'
import { toast, replaceAndParse, getInitialState } from '../utils'
import { handleError } from '../utils/errors'
import { stationTransformer } from '../utils/transformers'
import { initI18n } from '../i18n'
import { stations as stationsSelector } from '../selectors/station';
import {
  station as stationSelector,
  stationDebitBank, stationCreditBank, stationTransferBank, stationCloseBank, stationBaseBank,
  stationWarehouse,
  stationInvoiceNumeration, stationCashReceiptNumeration, stationRefundNumeration,
  offlineData,
  APIGraphqlSelector
} from '../selectors/app';
import { languageSelector, hasPermissionTo, isAdmin as isAdminSelector } from '../selectors/auth';
import {
  shiftsEnabled,
  shiftsConfigured,
  isOnlyInvoicingPlan,
  country as countrySelector,
  companySelector
} from '../selectors/company';
import { isOpen } from '../selectors/shifts';
import { isCashReceiptNumerationActive, isRefundNumerationActive } from '../selectors/numerations';
import { startDB, cleanDB } from '../database/database';
import { clear as clearItems } from '../database/itemsDB';
import { total as invoicesOffline } from '../database/invoicesDB';
import { clear as clearPendingInvoices } from '../database/pendingInvoicesDB';
import {
  clear as clearClients,
  totalOffline as contactsOffline,
  getPOSClient as getLocalClient
} from '../database/contactsDB';
import { fetchUser } from './auth';
import { getCompanyValues, sendGTMEvent } from './company';
import { fetchUsers } from './users';
import { fetchStations } from './stations';
import { fetchItems, fetchFavorites, syncItems } from './items';
import { syncOffline as syncInvoices } from './activeInvoice';
import { fetchClients, syncClients } from './clients';
import { fetchRegimes } from './regimes';
import { getShiftStatus } from './shifts';
import { fetchMembership, createMembership, fetchHappyWeekStatus } from './membership';
import { syncOffline } from './activeInvoice';
import { openModal } from './modals';
import { fetchUnits } from './units';
import { loadAditionalSettings } from './aditionalSettings';
import alegraAPI from './alegraAPI';
import { queryClient } from '../utils/queryClient';
import { getAllTotalToCollect } from './totalToCollect';
import { COUNTRIES } from '../utils/enums/countries';

const initialState = {
  initApp: {
    loading: true,
    success: false,
    error: null,
  },
  station: null,
  menuOpen: false,
  initHubspot: false,
  syncing: true,
  syncingInvoice: false,
  lastSync: null,
  offlineStatus: {
    invoices: false,
    contacts: false,
  },
  networkStatus: 'online',
  searchProductType: 'text',
  searchValue: '',
  blockApiCalls: false,
}

export const initApp = createAsyncThunk(
  'app/initApp',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const start = new Date();
      await startDB();

      const storedStation = store.get('station');

      await dispatch(fetchUser()).then(unwrapResult)

      const globalState = store.get('globalState');
      const company = companySelector(getState());

      if (get(globalState, 'auth.company.id', '') !== get(company, 'id', '')) {
        store.remove('globalState')
      }

      await Promise.all([
        dispatch(fetchStations()).then(unwrapResult),
        dispatch(checkOffline())
      ])

      const language = languageSelector(getState());
      initI18n(language)
      const POS = I18n.get('AlegraPOS', 'POS');
      document.title = "Alegra " + POS;

      if (!!get(storedStation, 'id', null)) {
        const stations = stationsSelector(getState());

        const selectedStation = stations.find(station =>
          station.id === storedStation.id && station.idCompany === storedStation.idCompany
        )

        if (!!selectedStation && !!selectedStation.isActive)
          dispatch(setStation(selectedStation))
        else {
          if (!!offlineData(getState()))
            return rejectWithValue("Offline data");

          store.remove('globalState')
          store.remove('station');
          store.remove('lastSyncDatetime');
          await Promise.all([
            clearItems(),
            clearClients(),
            clearPendingInvoices(),
          ])
        }
      } else {
        store.remove('globalState')
        dispatch(setStation(null))
        store.remove('lastSyncDatetime');
        await Promise.all([
          clearItems(),
          clearClients(),
          clearPendingInvoices(),
        ])
      }

      const end = new Date();
      dispatch(sendGTMEvent("data-loaded", {
        status: "READY_TO_WORK",
        durationInSeconds: Math.floor((end - start) / 1000),
      }));
    } catch (error) {
      return rejectWithValue(handleError(error, error));
    }
  }
)

export const loadData = createAsyncThunk(
  'app/loadData',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const start = new Date();
      const station = stationSelector(getState());
      const shifts = shiftsEnabled(getState());
      const country = countrySelector(getState());
      const can = hasPermissionTo(getState())
      const isAdmin = isAdminSelector(getState());

      const storedLastSyncDatetime = store.get('lastSyncDatetime');
      let lastSyncDatetime = null;
      try {
        const APIGraphql = APIGraphqlSelector(getState());

        const response = await APIGraphql(graphqlOperation(mutations.updateLastSyncDatetime));
        lastSyncDatetime = get(response, "data.updateLastSyncDatetime.lastSyncDatetime");
      } catch (error) {
        console.log(error);
      }

      await Promise.all([
        dispatch(fetchMembership()),
        dispatch(fetchHappyWeekStatus()),
        dispatch(fetchItems({ idWarehouse: station.idWarehouse })),
        dispatch(fetchFavorites()),
        can('view', 'contacts')
          ? dispatch(fetchClients({ lastSyncDatetime: storedLastSyncDatetime })) : Promise.resolve(),
        dispatch(getCompanyValues()),
        dispatch(loadAditionalSettings()),
        isAdmin
          ? dispatch(fetchUsers()) : Promise.resolve(),
        dispatch(fetchRegimes()),
        dispatch(fetchUnits({ applicationVersion: country })),
        shifts ? dispatch(getShiftStatus({ idStation: station.id })) : Promise.resolve(),
        dispatch(getAllTotalToCollect())
      ])

      try {
        await dispatch(createMembership())
      } catch (error) {
        console.error(error)
      }

      if (!!lastSyncDatetime)
        store.set('lastSyncDatetime', lastSyncDatetime);

      const end = new Date();
      dispatch(sendGTMEvent("data-loaded", {
        status: "READY_TO_WORK_OFFLINE",
        durationInSeconds: Math.floor((end - start) / 1000),
      }));

      setInterval(() => dispatch(syncData(true)), 1800000);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const syncData = createAsyncThunk(
  'app/syncData',
  async (auto, { rejectWithValue, dispatch, getState }) => {
    try {

      await Promise.all([
        dispatch(fetchUser()),
        dispatch(fetchStations()),
      ])

      const station = stationSelector(getState());
      const shifts = shiftsEnabled(getState());
      const can = hasPermissionTo(getState());
      const isAdmin = isAdminSelector(getState());

      let syncError = null;

      try {
        await dispatch(syncInvoices(true));
      }
      catch (error) {
        syncError = error;
      }

      await Promise.all([
        dispatch(fetchMembership()),
        !auto
          ? dispatch(syncItems({ idWarehouse: station.idWarehouse })) : Promise.resolve(),
        dispatch(fetchFavorites()),
        can('view', 'contacts') && !auto
          ? dispatch(syncClients()) : Promise.resolve(),
        dispatch(getCompanyValues()),
        isAdmin
          ? dispatch(fetchUsers()) : Promise.resolve(),
        dispatch(fetchRegimes()),
        shifts ? dispatch(getShiftStatus({ idStation: station.id })) : Promise.resolve(),
        dispatch(syncOffline(true)),
      ])

      if (syncError)
        throw syncError;

      if (auto) {
        dispatch(sendGTMEvent("sync-attempted", {
          autoSyncStatus: "success"
        }))
      };

    } catch (error) {
      if (auto) {
        dispatch(sendGTMEvent("sync-attempted", {
          autoSyncStatus: "failure"
        }))
      }
      if (!auto) {
        toast.error({
          title: I18n.get('syncError', 'error en sincronización'),
          subtitle: replaceAndParse(handleError(error, I18n.get('syncErrorSubtitle', 'los errores en la sincronización suelen ser por datos en offline, falta de permisos o problemas de conexión')))
        })
        return rejectWithValue(handleError(error));
      }
    }
  }
)

export const logout = createAsyncThunk(
  'app/logout',
  async (_, { getState, rejectWithValue }) => {
    try {
      console.time('Remove Cookies');
      Cookie.remove(process.env.REACT_APP_ALEGRA_COOKIE, {
        path: '/',
        domain: '.alegra.com',
      });
      Cookie.remove('happy-week-may23');
      console.timeEnd('Remove Cookies');

      console.time('Clean DB');
      await Promise.all([
        cleanDB(),
        queryClient.invalidateQueries(),
        queryClient.clear(),
      ]);
      console.timeEnd('Clean DB');

      console.time('Remove Local Storage');
      store.clearAll();
      window.localStorage.clear();
      console.timeEnd('Remove Local Storage');

      console.time('redirect');
      if (!window.Cypress) {
        window.location.replace(
          `${process.env.REACT_APP_ALEGRA}user/login/pos`
        );
      }
      console.timeEnd('redirect');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const activateBlockApiCall = () => {
  console.log("activateBlockApiCall")
  Cookie.set("pos-block-api-request", 1);
}

export const deactivateBlockApiCall = () => {
  console.log("deactivateBlockApiCall")
  Cookie.remove("pos-block-api-request");
}

export const checkOffline = createAsyncThunk(
  'app/checkOffline',
  async (_, { dispatch }) => {
    try {
      const invoices = await invoicesOffline()
      const contacts = await contactsOffline()

      dispatch(setOfflineStatus({ invoices: !!invoices }))
      dispatch(setOfflineStatus({ contacts: !!contacts }))
    } catch {
    }
  }
)

const appSlice = createSlice({
  name: 'app',
  initialState: getInitialState('app', initialState),
  reducers: {
    openMenu: state => {
      state.menuOpen = true;
    },
    closeMenu: state => {
      state.menuOpen = false;
    },
    setStation: (state, action) => {
      store.set('station', action.payload);
      state.station = action.payload;
    },
    updateStation: (state, action) => {
      const updatedStation = { ...state.station, ...action.payload }
      store.set('station', updatedStation);
      state.station = updatedStation;
    },
    hubSpotInitialized: state => {
      state.initHubspot = true
    },
    setOfflineStatus: (state, action) => {
      state.offlineStatus = {
        ...state.offlineStatus,
        ...action.payload,
      }
    },
    setSyncingInvoice: (state, action) => {
      state.syncingInvoice = action.payload;
    },
    setNetworkStatus: (state, action) => {
      state.networkStatus = action.payload
    },
    setSearchProductType: (state, action) => {
      state.searchProductType = action.payload + (Math.random() * (100 - 1) + 1)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initApp.pending, (state) => {
      state.initApp = {
        loading: true,
        success: false,
        error: null,
      }
    })
    builder.addCase(initApp.fulfilled, (state) => {
      state.initApp = {
        loading: false,
        success: true,
        error: null,
      }
    })
    builder.addCase(initApp.rejected, (state, action) => {
      state.initApp.error = action.payload
      state.initApp.loading = false;
    })
    builder.addCase(loadData.pending, (state) => {
      state.syncing = true
    })
    builder.addCase(loadData.fulfilled, (state) => {
      state.syncing = false
      state.lastSync = new Date().toISOString()
    })
    builder.addCase(syncData.pending, (state) => {
      state.syncing = true
    })
    builder.addCase(syncData.fulfilled, (state) => {
      state.syncing = false
      state.lastSync = new Date().toISOString()
    })
    builder.addCase(syncData.rejected, (state) => {
      state.syncing = false
    })
  }
});

const { actions, reducer } = appSlice;

export const {
  openMenu,
  closeMenu,
  setStation,
  setOfflineStatus,
  hubSpotInitialized,
  setNetworkStatus,
  setSearchProductType,
  setSearchValue,
  setSyncingInvoice,
} = actions;

export default reducer;

export const updateStation = createAsyncThunk(
  'app/updateStation',
  async (payload, { dispatch, getState }) => {
    const station = getState().app.station;
    const updatedStation = { ...station, ...payload }
    store.set('station', updatedStation);
    dispatch(setStation(updatedStation))

    if (payload.hasOwnProperty('idWarehouse') && get(payload, 'idWarehouse') !== get(station, 'idWarehouse')) {
      await clearItems();
      dispatch(fetchItems({ idWarehouse: get(payload, 'idWarehouse') }))
    }

    if (payload.hasOwnProperty('pendingInvoicesEnabled')
      && get(payload, 'pendingInvoicesEnabled') !== get(station, 'pendingInvoicesEnabled')
      && !get(payload, 'pendingInvoicesEnabled')
    )
      await clearPendingInvoices();
  }
)

const checkShift = ({ isShiftConfigured, isShiftEnabled, isShiftOpen, baseBank, onlyInvoicing }) => {
  if (onlyInvoicing) return {}

  if (isShiftConfigured) {
    if (isShiftEnabled && !isShiftOpen)
      return { openShift: true }
    if (isShiftEnabled && !baseBank)
      return { baseBank: true }
    return {}
  }
  return { configureShift: true }
}

export const checkStationValues = ({ type, ...props }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const station = stationSelector(state)
    const isShiftConfigured = shiftsConfigured(state)
    const isShiftEnabled = shiftsEnabled(state)
    const isShiftOpen = isOpen(state)
    const onlyInvoicing = isOnlyInvoicingPlan(state)
    const isCashReceiptActive = isCashReceiptNumerationActive(state)
    const isRefundActive = isRefundNumerationActive(state)
    const debitBank = stationDebitBank(state)
    const creditBank = stationCreditBank(state)
    const transferBank = stationTransferBank(state)
    const baseBank = stationBaseBank(state)
    const closeBank = stationCloseBank(state)
    const warehouse = stationWarehouse(state)
    const invoiceNumeration = stationInvoiceNumeration(state)
    const cashReceiptNumeration = stationCashReceiptNumeration(state)
    const refundNumeration = stationRefundNumeration(state)
    const APIGraphql = APIGraphqlSelector(state);

    let modalParams = {};
    let stationValues = {};

    switch (type) {
      case 'invoice':
        modalParams = {
          ...modalParams, ...checkShift({
            isShiftConfigured, isShiftEnabled, isShiftOpen, baseBank, onlyInvoicing
          })
        }

        if (!!invoiceNumeration) {
          if (+get(station, 'idNumberTemplate') !== +get(invoiceNumeration, 'id')) {
            stationValues = { ...stationValues, idNumberTemplate: +get(invoiceNumeration, 'id') }
          }
        } else {
          modalParams = { ...modalParams, invoiceNumeration: true }
        }

        if (isCashReceiptActive) {
          if (!!cashReceiptNumeration) {
            if (+get(station, 'idCashReceiptNumberTemplate') !== +get(cashReceiptNumeration, 'id')) {
              stationValues = { ...stationValues, idCashReceiptNumberTemplate: +get(cashReceiptNumeration, 'id') }
            }
          } else {
            modalParams = { ...modalParams, cashReceiptNumeration: true }
          }
        }

        if (!!get(props, 'values.debit') && !debitBank && !onlyInvoicing)
          modalParams = { ...modalParams, debitBank: true }
        if (!!get(props, 'values.credit') && !creditBank && !onlyInvoicing)
          modalParams = { ...modalParams, creditBank: true }
        if (!!get(props, 'values.transfer') && !transferBank && !onlyInvoicing)
          modalParams = { ...modalParams, transferBank: true }

        if (!!warehouse) {
          if (+get(station, 'idWarehouse') !== +get(warehouse, 'id')) {
            stationValues = { ...stationValues, idWarehouse: +get(warehouse, 'id') }
          }
        } else {
          modalParams = { ...modalParams, warehouse: true }
        }

        break;

      case 'payment':
        modalParams = {
          ...modalParams, ...checkShift({
            isShiftConfigured, isShiftEnabled, isShiftOpen, baseBank, onlyInvoicing
          })
        }

        if (isCashReceiptActive && get(props, 'values.type') === 'in') {
          if (!!cashReceiptNumeration) {
            if (+get(station, 'idCashReceiptNumberTemplate') !== +get(cashReceiptNumeration, 'id')) {
              stationValues = { ...stationValues, idCashReceiptNumberTemplate: +get(cashReceiptNumeration, 'id') }
            }
          } else {
            modalParams = { ...modalParams, cashReceiptNumeration: true }
          }
        }
        break;

      case 'refund':
        modalParams = {
          ...modalParams, ...checkShift({
            isShiftConfigured, isShiftEnabled, isShiftOpen, baseBank, onlyInvoicing
          })
        }

        if (isRefundActive) {
          if (!!refundNumeration) {
            if (+get(station, 'idRefundNumberTemplate') !== +get(refundNumeration, 'id')) {
              stationValues = { ...stationValues, idRefundNumberTemplate: +get(refundNumeration, 'id') }
            }
          } else {
            modalParams = { ...modalParams, refundNumeration: true }
          }
        }

        if (!!warehouse) {
          if (+get(station, 'idWarehouse') !== +get(warehouse, 'id')) {
            stationValues = { ...stationValues, idWarehouse: +get(warehouse, 'id') }
          }
        } else {
          modalParams = { ...modalParams, warehouse: true }
        }

        break;

      case 'closeShift':
        if (!closeBank && !onlyInvoicing)
          modalParams = { ...modalParams, closeBank: true }
        break;

      case 'edit-invoice':
        if (!!get(props, 'values.payments.debit') && !debitBank && !onlyInvoicing)
          modalParams = { ...modalParams, debitBank: true }
        if (!!get(props, 'values.payments.credit') && !creditBank && !onlyInvoicing)
          modalParams = { ...modalParams, creditBank: true }
        if (!!get(props, 'values.payments.transfer') && !transferBank && !onlyInvoicing)
          modalParams = { ...modalParams, transferBank: true }

        break;

      default:
        return true;
    }

    if (!!Object.keys(stationValues).length) {
      try {
        const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
          station: stationTransformer({ ...station, ...stationValues })
        }))

        const updatedStation = get(response, 'data.updateStation')
        if (!!updatedStation)
          dispatch(updateStation(updatedStation))
      } catch (error) {
        throw handleError(error)
      }
    }

    if (!!Object.keys(modalParams).length) {
      dispatch(openModal({ modal: 'stationSettings', params: modalParams }))
      return false;
    }
    return true;
  }
}

const clientParams = country => {
  switch (country) {
    case 'costaRica':
      return { name: "Cliente de contado", identification: "100000000" };
    case 'peru':
      return { name: "Cliente varios", identification: "00000000" };
    case 'colombia':
      return { name: "Consumidor final", identification: "222222222222" };
    case COUNTRIES.MEXICO:
      return {
        name: "Público en General",
        identification: "XAXX010101000",
        regime: 'SIMPLIFIED_REGIME',
        regimeObject: ['SIMPLIFIED_REGIME'],
        address: {
          country: "MEX",
          zipCode: '12345'
        }
      };
    case 'argentina':
      return { name: "Consumidor final", identification: "1" };
    case 'republicaDominicana':
      return { name: "Consumidor final" };
    case 'panama':
      return { name: "Consumidor final" };
    case 'spain':
      return { name: 'Público en general', identification: 'X0101010Y' };
    default:
      return { name: 'POS' };
  }
}


export const getPOSClient = () => {
  return async (_dispatch, getState) => {
    const state = getState()
    const country = countrySelector(state)
    let client = null;

    // ex queries.allClients
    try {
      client = await getLocalClient(country);

      if (!client) {
        client = await getLocalClient(country, 'Público en General');
      }

      if (!!client && client?.id) return client

      const getResponse = await alegraAPI.get('/contacts', {
        ...clientParams(country)
      })

      if (country === 'argentina' && !!get(getResponse, 'data')) {
        client = find(get(getResponse, 'data'), { name: 'Consumidor final', identification: '1' })
        return client;
      }

      if (get(getResponse, 'data.0')) return get(getResponse, 'data.0')
    } catch {
      return null
    }
  }
}