import { get, isArray, isNumber } from 'lodash';
import { BigNumber } from 'bignumber.js';
import { I18n } from 'aws-amplify';
import dayjs from 'dayjs';
import countries from '../components/countriesData/general/countries'
import * as toast from './toast'
import { fetch } from './fetchPaginated';
import parse from 'html-react-parser';
import { replace } from 'lodash';
import store from 'store2';
import { Buffer} from 'buffer'
import { calculateAvailabilityByPlanKeyword, calculateAvailabilityByRegistryDate, DECIMALS_ACTIVE_COUNTRIES, DECIMALS_ACTIVE_IDS } from '../hooks/useDecimalsVersionsGroup/hook';
import { availableIntermittenceCompanies, availableIntermittenceCountries } from '../hooks/useOfflineStatusAvailable/hook';
import { COUNTRIES } from './enums/countries';
import { DEFAULT_LOCAL_CLIENTS } from './enums/defaultClients';

const clientDataAreEquals = (stringA = "", stringB = "") => {
  return stringA.toLowerCase().includes(stringB.toLowerCase());
}

export const isDefaultClient = (client, country) => {
  const defaultC = basicPOSClientData(country)
  try {
    switch (country) {
      case 'costaRica':
        return clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identificationObject.type, client.identificationObject.type)
          && clientDataAreEquals(defaultC.identificationObject.number, client.identificationObject.number)
      case 'peru':
        return (clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identification.type, client.identification.type)
          && clientDataAreEquals(defaultC.identification.number, client.identification.number))
          || (clientDataAreEquals("Cliente general", client.name)
          && clientDataAreEquals("DNI", client.identification.type)
          && clientDataAreEquals("00000000", client.identification.number))
      case 'argentina':
        return clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identification.type, client.identification.type)
          && clientDataAreEquals(defaultC.identification.number, client.identification.number)
      default:
        false
    }

  } catch {
    return false
  }
}

export const basicPOSClientData = (country, numeration) => {
  switch (country) {
    case 'costaRica':
      return DEFAULT_LOCAL_CLIENTS.costaRica
    case 'peru':
      return DEFAULT_LOCAL_CLIENTS.peru
    case 'colombia':
      return DEFAULT_LOCAL_CLIENTS.colombia
    case 'mexico':
      return DEFAULT_LOCAL_CLIENTS.mexico
    case 'argentina':
      return DEFAULT_LOCAL_CLIENTS.argentina
    case 'republicaDominicana':
      if (!!numeration && numeration.prefix === 'E31') return null
      return DEFAULT_LOCAL_CLIENTS.republicaDominicana
    case 'panama':
      return DEFAULT_LOCAL_CLIENTS.panama
    case 'spain':
      if (!!numeration && numeration.documentType === 'invoice') {
        return null
      }
      return DEFAULT_LOCAL_CLIENTS.spain
    default:
      return DEFAULT_LOCAL_CLIENTS.default
  }
}

export const mexicoDefaultPaymentValues = {
  paymentMethod: 'Pago en un sola exhibición (PUE)',
  paymentWay: 'Efectivo',
  paymentUse: 'Sin efectos fiscales',
}

const calculateTotalByCountry = (subtotal, taxes, country) => {
  if(country === COUNTRIES.MEXICO) {
    return subtotal.decimalPlaces(2).plus(
      Object.values(taxes)
        .map(tax => tax.value.decimalPlaces(2))
        .reduce((p, c) => p.plus(c), new BigNumber(0))
    )
  }

  return subtotal.plus(
    Object.values(taxes)
      .map(tax => tax.value)
      .reduce((p, c) => p.plus(c), new BigNumber(0))
  )
}

export const calculateSingleItemValuesWithDecimals = (item, decimal, ignoreDisableTaxes = false, country) => {
  //const decimal = 6;
  let subtotal = new BigNumber(0);
  let discount = new BigNumber(0);
  let iepsValue = new BigNumber(0);
  let taxesArrayFinal = [];
  let taxes = {};

  const itemQuantity = !!item.quantity
    ? new BigNumber(item.quantity)
    : new BigNumber(1)
  const itemPrice = !!item.price
      ? new BigNumber(item.price)
      : new BigNumber(0)

  const itemDiscountPercent = new BigNumber(!!isNumber(get(item, 'discount.discount', null))
    ? get(item, 'discount.discount')
    : !!get(item, 'discount', null)
      ? get(item, 'discount') : 0
  )

  const itemSubtotal = itemQuantity.multipliedBy(itemPrice);
  subtotal = subtotal.plus(itemSubtotal);

  const discountSubtotal = new BigNumber(new BigNumber(1).minus(itemDiscountPercent.dividedBy(100)))
    .multipliedBy(itemPrice)
    .multipliedBy(itemQuantity)
    .abs();

  const itemDiscount = itemSubtotal.minus(discountSubtotal);
  discount = discount.plus(itemDiscount);
  const discSubtotal = subtotal.minus(discount);
  if (!!item.tax) {
    const taxesArray = isArray(item.tax)
      ? item.tax : [item.tax]

    const haveIEPStax = taxesArray.some(t => get(t, "type", '') === "IEPS");
    
    iepsValue = taxesArray.reduce((acc, t) => {
      if (get(t, "type", '') === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(itemSubtotal))
      }
      return acc
    }, new BigNumber(0))

    taxesArray.map(t => {
      const isTaxEnabled = !!ignoreDisableTaxes ? true : get(t, 'status') === 'active';
      if (isTaxEnabled && !get(t, 'noIVA')) {
        const isTaxIVA = get(t, 'type') === 'IVA';
        const taxValue = new BigNumber(get(taxes, `${t.id}.value`, 0));
        const taxPercent = new BigNumber(t.percentage);
        let tax = new BigNumber(0);

        if (haveIEPStax && isTaxIVA) {
          tax = taxPercent.dividedBy(100).multipliedBy(discSubtotal.plus(iepsValue))
        } else {
          tax = taxPercent.dividedBy(100).multipliedBy(discSubtotal)
        }

        const taxObject = {
          value: tax,
          name: get(t, "name"),
          percentage: get(t, "percentage"),
          id: get(t, "id"),
          rate: get(t, 'rate', null),
          type: get(t, 'type', null)
        }

        taxesArrayFinal.push(taxObject);

        taxes[t.id] = !!taxes[t.id]
          ? {
            ...taxes[t.id],
            value: taxValue.plus(tax)
          }
          : {
            value: tax,
            name: `${t.name} (${t.percentage}%)`,
            rate: get(t, 'rate', null)
          }
      }
      return null;
    })
  }
  
  const taxesResult = Object.values(taxes).map((tax) => {
    let taxName = tax.name;
    const rate =
      get(tax, 'rate', null) === 'EXCLUDED'
        ? ' - Excluido'
        : get(tax, 'rate', null) === 'EXEMPT'
          ? ' - Exento'
          : null;
    if (rate) {
      taxName = `${taxName} ${rate}`;
    }
    return taxName;
  });
  return {
    price: itemPrice.decimalPlaces(decimal),
    discount: itemDiscountPercent.decimalPlaces(decimal).toNumber(),
    discountValue: itemDiscount.decimalPlaces(decimal),
    quantity: itemQuantity.decimalPlaces(2).toNumber(),
    discSubtotal: discSubtotal.decimalPlaces(decimal),
    subtotal: subtotal.decimalPlaces(decimal),
    taxes: taxesResult.join(', '),
    taxValue: Object.values(taxes)
      .map(tax => tax.value)
      .reduce((p, c) => p.plus(c), new BigNumber(0)),
    total: calculateTotalByCountry(discSubtotal, taxes, country).decimalPlaces(decimal),
    iepsValue: iepsValue,
    taxesArray: taxesArrayFinal
  }
}

export const calculateSingleItemValuesWithoutDecimals = (item, decimal, ignoreDisableTaxes = false, country) => {
  //const decimal = 6;
  let subtotal = new BigNumber(0);
  let discount = new BigNumber(0);
  let iepsValue = new BigNumber(0);
  let taxes = {};

  const itemQuantity = !!item.quantity
    ? new BigNumber(item.quantity)
    : new BigNumber(1)
  const itemPrice = !!item.price
      ? new BigNumber(item.price)
      : new BigNumber(0)

  const itemDiscountPercent = new BigNumber(!!isNumber(get(item, 'discount.discount', null))
    ? get(item, 'discount.discount')
    : !!get(item, 'discount', null)
      ? get(item, 'discount') : 0
  )

  const itemSubtotal = itemQuantity.multipliedBy(itemPrice);
  subtotal = subtotal.plus(itemSubtotal);

  const discountSubtotal = new BigNumber(new BigNumber(1).minus(itemDiscountPercent.dividedBy(100)))
    .multipliedBy(itemPrice)
    .multipliedBy(itemQuantity)
    .abs();

  const itemDiscount = itemSubtotal.minus(discountSubtotal);
  discount = discount.plus(itemDiscount);
  const discSubtotal = subtotal.minus(discount);
  if (!!item.tax) {
    const taxesArray = isArray(item.tax)
      ? item.tax : [item.tax]
    
    const haveIEPStax = taxesArray.some(t => get(t, "type", '') === "IEPS");
    

    iepsValue = taxesArray.reduce((acc, t) => {
      if (get(t, "type", '') === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(itemSubtotal))
      }
      return acc
    }, new BigNumber(0))

    taxesArray.map(t => {
      const isTaxEnabled = !!ignoreDisableTaxes ? true : get(t, 'status') === 'active';
      if (isTaxEnabled && !get(t, 'noIVA')) {
        const isTaxIVA = get(t, 'type') === 'IVA';
        const taxValue = new BigNumber(get(taxes, `${t.id}.value`, 0));
        const taxPercent = new BigNumber(t.percentage);
        let tax = new BigNumber(0);

        if (haveIEPStax && isTaxIVA) {
          tax = taxPercent.dividedBy(100).multipliedBy(discSubtotal.plus(iepsValue))
        } else {
          tax = taxPercent.dividedBy(100).multipliedBy(discSubtotal)
        }

        taxes[t.id] = !!taxes[t.id]
          ? {
            ...taxes[t.id],
            value: taxValue.plus(tax)
          }
          : {
            value: tax,
            name: `${t.name} (${t.percentage}%)`
          }
      }
      return null;
    })
  }

  return {
    price: itemPrice,
    discount: itemDiscountPercent.toNumber(),
    discountValue: itemDiscount,
    quantity: itemQuantity.toNumber(),
    discSubtotal: discSubtotal,
    subtotal: subtotal,
    taxes: Object.values(taxes)
      .map(tax => tax.name).join(', '),
    taxValue: Object.values(taxes)
      .map(tax => tax.value)
      .reduce((p, c) => p.plus(c), new BigNumber(0)),
    total: calculateTotalByCountry(discSubtotal, taxes, country),
    iepsValue,
  }
}

export const calculateSingleItemValues = (item, decimal, ignoreDisableTaxes = false) => {
  //const decimal = 6;
  let subtotal = new BigNumber(0);
  let discount = new BigNumber(0);
  let iepsValue = new BigNumber(0);
  let taxesArrayFinal = [];
  let taxes = {};

  const itemQuantity = !!item.quantity
    ? new BigNumber(item.quantity).decimalPlaces(2)
    : new BigNumber(1).decimalPlaces(2)
  const itemPrice = !!item.price
      ? new BigNumber(item.price).decimalPlaces(decimal)
      : new BigNumber(0).decimalPlaces(decimal)
  const itemDiscountPercent = new BigNumber(!!isNumber(get(item, 'discount.discount', null))
    ? get(item, 'discount.discount')
    : !!get(item, 'discount', null)
      ? get(item, 'discount') : 0
  ).decimalPlaces(2);

  const itemSubtotal = itemQuantity.multipliedBy(itemPrice).decimalPlaces(decimal);
  subtotal = subtotal.plus(itemSubtotal);

  const discountSubtotal = new BigNumber(new BigNumber(1).minus(itemDiscountPercent.dividedBy(100)))
    .multipliedBy(itemPrice).decimalPlaces(decimal)
    .multipliedBy(itemQuantity).decimalPlaces(decimal)
    .abs().decimalPlaces(decimal)

  const itemDiscount = itemSubtotal.minus(discountSubtotal);
  discount = discount.plus(itemDiscount)
  const discSubtotal = subtotal.minus(discount);

  if (!!item.tax) {
    const taxesArray = isArray(item.tax)
      ? item.tax : [item.tax]

    const haveIEPStax = taxesArray.some(t => get(t, "type", '') === "IEPS");

    iepsValue = taxesArray.reduce((acc, t) => {
      if (get(t, "type", '') === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(itemSubtotal))
      }
      return acc
    }, new BigNumber(0))

    taxesArray.map(t => {
      const isTaxEnabled = !!ignoreDisableTaxes ? true : get(t, 'status') === 'active';
      if (isTaxEnabled && !get(t, 'noIVA')) {
        const isTaxIVA = get(t, 'type') === 'IVA';
        const taxValue = new BigNumber(get(taxes, `${t.id}.value`, 0));
        const taxPercent = new BigNumber(t.percentage);
        let tax = new BigNumber(0);

        if (haveIEPStax && isTaxIVA) {
          tax = taxPercent.dividedBy(100).multipliedBy(discSubtotal.plus(iepsValue)).decimalPlaces(decimal)
        } else {
          tax = taxPercent.dividedBy(100).multipliedBy(discSubtotal).decimalPlaces(decimal)
        }

        const taxObject = {
          value: tax,
          name: get(t, "name"),
          percentage: get(t, "percentage"),
          id: get(t, "id"),
          rate: get(t, 'rate', null),
          type: get(t, 'type', null)
        }

        taxesArrayFinal.push(taxObject);

        taxes[t.id] = !!taxes[t.id]
          ? {
            ...taxes[t.id],
            value: taxValue.plus(tax)
          }
          : {
            value: tax,
            name: `${t.name} (${t.percentage}%)`,
            rate: get(t, 'rate', null)
          }
      }
      return null;
    })
  }

  const taxesResult = Object.values(taxes).map((tax) => {
    let taxName = tax.name;
    const rate = get(tax, 'rate', null) === 'EXCLUDED' ? ' - Excluido': get(tax, 'rate', null) === 'EXEMPT' ? ' - Exento' : null;
    if (rate) {
      taxName = `${taxName} ${rate}`;
    }
    return taxName;
  });
  return {
    price: itemPrice.decimalPlaces(decimal),
    discount: itemDiscountPercent.decimalPlaces(decimal).toNumber(),
    discountValue: itemDiscount.decimalPlaces(decimal),
    quantity: itemQuantity.decimalPlaces(2).toNumber(),
    discSubtotal: discSubtotal.decimalPlaces(decimal),
    subtotal: subtotal.decimalPlaces(decimal),
    taxes: taxesResult.join(', '),
    taxValue: Object.values(taxes)
      .map(tax => tax.value)
      .reduce((p, c) => p.plus(c), new BigNumber(0)),
    total: discSubtotal.plus(
      Object.values(taxes)
        .map(tax => tax.value)
        .reduce((p, c) => p.plus(c), new BigNumber(0))
    ).decimalPlaces(decimal),
    iepsValue: iepsValue,
    taxesArray: taxesArrayFinal
  }
}

const calulateTaxValues = ({taxes, haveIEPStax, decimal, country, t, company, discSubtotal, iepsValue, membership}) => {
  const isTaxIVA = get(t, 'type') === 'IVA';
  let taxValue = new BigNumber(get(taxes, `${t.id}.value`, 0));
  const taxPercent = new BigNumber(t.percentage);
  let tax = BigNumber(0);
  
  const validateDecimalsByRegistryDate = calculateAvailabilityByRegistryDate({company, country});
  const validateDecimalsByPlan = calculateAvailabilityByPlanKeyword({membership, country});
  const isDecimalsActive = DECIMALS_ACTIVE_COUNTRIES.includes(country) || DECIMALS_ACTIVE_IDS.includes(company?.id) || validateDecimalsByRegistryDate || validateDecimalsByPlan;

  if (haveIEPStax && isTaxIVA) {
    // cuando hay ieps el iva se calcula sumando el total del item que es singleItemValues.discSubtotal y el impuesto ieps 
    // y luego se calcula el iva sobre ese total, por eso se le suma el impuesto ieps al singleItemValues.discSubtotal
    tax = isDecimalsActive
      ? taxPercent
        .dividedBy(100)
        .multipliedBy(discSubtotal.plus(iepsValue))
      : taxPercent
        .dividedBy(100)
        .multipliedBy(discSubtotal.plus(iepsValue))
        .decimalPlaces(decimal);
  } else {
    tax = isDecimalsActive
      ? taxPercent
          .dividedBy(100)
          .multipliedBy(discSubtotal)
      : taxPercent
          .dividedBy(100)
          .multipliedBy(discSubtotal)
          .decimalPlaces(decimal);
  }

  return {
    taxValue,
    taxPercent,
    tax
  }
}

export const calculateItemsValues = ({items, decimal = 0, tips, country, company, ignoreDisableTaxes = false, priceList, membership}) => {
  let subtotal = new BigNumber(0);
  let discount = new BigNumber(0);
  let discSubtotal = new BigNumber(0);
  let total = new BigNumber(0);
  let taxes = {};
  let subTotalByTax = {};
  let taxesByType = {};
  
  const validateDecimalsByRegistryDate = calculateAvailabilityByRegistryDate({company, country});
  const validateDecimalsByPlan = calculateAvailabilityByPlanKeyword({membership, country});
  const isDecimalsActive = DECIMALS_ACTIVE_COUNTRIES.includes(country) || DECIMALS_ACTIVE_IDS.includes(company?.id) || validateDecimalsByRegistryDate || validateDecimalsByPlan;

  items.map(item => {
    const copyItem = { ...item, currentPriceList: priceList }
    const singleItemValues = isDecimalsActive
      ? calculateSingleItemValuesWithoutDecimals(copyItem, decimal, ignoreDisableTaxes, country) 
      : calculateSingleItemValues(copyItem, decimal, ignoreDisableTaxes);
   
    if(isDecimalsActive) {
      subtotal = subtotal.plus(singleItemValues.price.multipliedBy(singleItemValues.quantity))
      discount = discount.plus(singleItemValues.discountValue)
      discSubtotal = discSubtotal.plus(singleItemValues.discSubtotal)
      total = total.plus(singleItemValues.total)
    } else {
      subtotal = subtotal.plus(singleItemValues.price.multipliedBy(singleItemValues.quantity)).decimalPlaces(decimal)
      discount = discount.plus(singleItemValues.discountValue).decimalPlaces(decimal)
      discSubtotal = discSubtotal.plus(singleItemValues.discSubtotal).decimalPlaces(decimal)
      total = total.plus(singleItemValues.total).decimalPlaces(decimal)
    }

    const itemTax = get(item, 'tax', null)

    if (itemTax) {
      const taxesArray = isArray(item.tax)
        ? item.tax : [item.tax]

        let haveIEPStax = false;
        if (country === COUNTRIES.MEXICO) {
          haveIEPStax = taxesArray.some(t => get(t, "type", '') === "IEPS");
        }

      taxesArray.map(t => {
        const isTaxEnabled = !!ignoreDisableTaxes ? true : get(t, 'status') === 'active';
        if (isTaxEnabled && !get(t, 'noIVA')) {
          const { taxValue, tax } = calulateTaxValues({
            taxes, haveIEPStax, decimal, country, t, company, 
            discSubtotal: singleItemValues.discSubtotal, iepsValue: singleItemValues.iepsValue,
            membership
          });

          taxes[t.id] = !!taxes[t.id]
            ? {
              ...taxes[t.id],
              type: !!get(t, 'type', null)
                ? get(t, 'type', null) === "EXENTO" ? "E" : get(t, 'type')
                : null,
              value: taxValue.plus(tax),
            }
            : {
              type: !!get(t, 'type', null)
                ? get(t, 'type', null) === "EXENTO" ? "E" : get(t, 'type')
                : null,
              percentage: get(t, 'percentage'),
              value: tax,
              name: `${t.name} (${t.percentage}%)`,
              id: get(t, 'id'),
              rate: country === COUNTRIES.COLOMBIA ? get(t, 'rate') === 'EXCLUDED' ? 'Excluido' : get(t, 'rate') === 'EXEMPT' ? 'Exento' : '': '',
            }
          subTotalByTax[t.id] = !!subTotalByTax[t.id]
            ? {
              id: t.id,
              value: subTotalByTax[t.id].value?.plus(singleItemValues.discSubtotal),
            }
            : {
              id: t.id,
              value: singleItemValues.discSubtotal,
            };
        }
        return null;
      })

      if (taxesArray.length === 0) {
        subTotalByTax[item.tax[0]?.id] = !!subTotalByTax[item.tax[0]?.id]
          ? {
            id: item.tax[0]?.id,
            value: subTotalByTax[item.tax[0]?.id].value?.plus(singleItemValues.discSubtotal)
          }
          : {
            id: item.tax[0]?.id,
            value: singleItemValues.discSubtotal
          }
      }
    }

    if (isArray(itemTax)) {
      taxesByType[item.tax[0]?.type] = !!taxesByType[item.tax[0]?.type]
        ? {
          type: item.tax[0]?.type,
          name: item.tax[0]?.name,
          value: taxesByType[item.tax[0]?.type].value?.plus(singleItemValues.taxValue)
        }
        : {
          type: item.tax[0]?.type,
          name: item.tax[0]?.name,
          value: singleItemValues.taxValue
        }
    }

    return null;
  })

  const totalWhitoutTips = total;
  
  if (!!tips && !!get(tips, 'include', false)) {
    const tip = tips.type === 'PERCENTAGE' ?
      subtotal.multipliedBy(tips.percentage).dividedBy(100).decimalPlaces(decimal) :
      new BigNumber(tips.value).decimalPlaces(decimal)
    total = total.plus(tip)
  }

  return {
    rawSubtotal: isDecimalsActive ? subtotal : subtotal.decimalPlaces(decimal),
    subtotal: subtotal.decimalPlaces(decimal),
    discount: discount.decimalPlaces(decimal),
    discSubtotal: discSubtotal.decimalPlaces(decimal),
    taxes: Object.values(taxes)
      .map(tax => ({
        ...tax,
        value: tax.value.decimalPlaces(decimal)
      })),
    total: total.decimalPlaces(decimal),
    totalWhitoutTips: totalWhitoutTips.decimalPlaces(decimal),
    subTotalByTax: Object.values(subTotalByTax)
      .map(subTotal => ({
        ...subTotal,
        value: subTotal.value.decimalPlaces(decimal)
      })),
    taxesByType: Object.values(taxesByType)
      .map(taxTotal => ({
        ...taxTotal,
        value: taxTotal.value.decimalPlaces(decimal)
      })).filter(taxTotal => !!taxTotal.type),
  }
}

export const formatMoney = (amount, decimal, format) => {
  return (new BigNumber(amount)).toFormat(decimal, format);
}

export const formatDateRelative = (date) => {
  const [year, month, day] = date.split('-').map(Number);
  const inputDate = new Date(year, month - 1, day);
  const inputDateMidnight = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

  const today = new Date();
  const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  if (inputDateMidnight.getTime() === currentDate.getTime()) {
    return I18n.get('today', 'HoyT');
  }

  const differenceInDays = (currentDate - inputDateMidnight) / (1000 * 60 * 60 * 24);

  if (differenceInDays === 1) {
    return I18n.get('yesterday', 'AyerT');
  }

  return dayjs(date).format(I18n.get("dateFormat", "YYYY/MM/DD"))
}

// export const formatDateRelative = (date, language) => {
//   const locale = language.replace("_", "-");

//   const formatter = new Intl.RelativeTimeFormat(locale, {
//     numeric: 'auto'
//   });

//   const DIVISIONS = [
//     { amount: 60, name: 'seconds' },
//     { amount: 60, name: 'minutes' },
//     { amount: 24, name: 'hours' },
//     { amount: 7, name: 'days' },
//     { amount: 4.34524, name: 'weeks' },
//     { amount: 12, name: 'months' },
//     { amount: Number.POSITIVE_INFINITY, name: 'years' }
//   ]

//   // Create currentDate with only the year, month, and day set to the current date
//   const today = new Date();
//   const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

//   // Parse the input date and set the time to midnight
//   const inputDate = new Date(date);
//   const inputDateMidnight = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

//   // Check if the input date is the same as the current date
//   if (inputDateMidnight.getTime() === currentDate.getTime()) {
//     return "today";
//   }

//   let duration = (inputDateMidnight - currentDate) / 1000;

//   for (let i = 0; i <= DIVISIONS.length; i++) {
//     const division = DIVISIONS[i];
//     const nextDuration = duration / division.amount;
//     const roundedDuration = Math.round(nextDuration);

//     // Compare the input date with the current date after each division
//     if (Math.abs(nextDuration) < 1) {
//       return formatter.format(roundedDuration, division.name);
//     }
//     duration = nextDuration;
//   }
// }

export const replaceAndParse = (string, replacement, pattern = "{}") => {
  let result = string;


  if (isArray(replacement))
    replacement.forEach(r => {
      result = replace(result, pattern, () => typeof r != 'undefined' ? r : '');
    });

  return parse(result);
}

export const styleBigNumber = (bigNumber, decimal) => {
  if (!decimal) {
    const intPart = bigNumber.slice(0, -(decimal));
    const decPart = bigNumber.slice(-(decimal));

    return {
      intPart,
      decPart,
    }
  }

  const intPart = bigNumber.slice(0, -(decimal + 1));
  const decPart = bigNumber.slice(-(decimal + 1));

  return {
    intPart,
    decPart,
  }
};

export const contactUsLink = country => {
  switch (country) {
    case 'colombia':
      return 'https://www.alegra.com/colombia/pos/contacto/'
    case 'republicaDominicana':
      return 'https://www.alegra.com/rdominicana/pos/contacto/'
    case 'argentina':
      return 'https://www.alegra.com/argentina/pos/contacto/'
    case 'spain':
      return 'https://www.alegra.com/es/pos/contacto/';
    case 'costaRica':
      return 'https://www.alegra.com/costarica/pos/contacto/';
    case 'usa':
      return 'https://www.alegra.com/usa/pos/contacto/';
    case 'peru':
      return 'https://www.alegra.com/peru/pos/contacto/';
    case 'mexico':
      return 'https://www.alegra.com/mexico/contacto/';
    case 'panama':
      return 'https://www.alegra.com/panama/pos/contacto/';
    default:
      return 'https://www.alegra.com/pos/contacto/'
  }
}

export const aboutPOSLink = country => {
  switch (country) {
    case 'argentina':
      return 'https://ayuda.alegra.com/es/facturaci%C3%B3n-%C3%A1gil-en-el-punto-de-venta-pos-argentina';
    default:
      return 'https://ayuda.alegra.com/hc/es/articles/208117766';
  }
}

export const createElectronicInvoicesLink = country => {
  switch (country) {
    case 'colombia':
      return 'https://ayuda.alegra.com/es/agiliza-la-facturaci%C3%B3n-del-terminal-de-tu-punto-de-venta-pos-colombia'
    default:
      return 'https://ayuda.alegra.com/es/agiliza-la-facturaci%C3%B3n-del-terminal-de-tu-punto-de-venta-pos-colombia'
  }
}

export const colors = ['#1abc9c', '#16a085', '#2ecc71', '#27ae60', '#3498db', '#2980b9',
  '#9b59b6', '#8e44ad', '#34495e', '#2c3e50', '#f1c40f', '#f39c12',
  '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#95a5a6', '#7f8c8d',
];

export const countryByVersion = (country) => {
  switch (country) {
    case 'colombia':
      return countries.find(c => c.key === 'COL')
    case 'costaRica':
      return countries.find(c => c.key === 'CRI')
    case 'peru':
      return countries.find(c => c.key === 'PER')
    case 'argentina':
      return countries.find(c => c.key === 'ARG')
    case 'mexico':
      return countries.find(c => c.key === 'MEX')
    case 'usa':
      return countries.find(c => c.key === 'USA')
    case 'republicaDominicana':
      return countries.find(c => c.key === 'DOM')
    case 'panama':
      return countries.find(c => c.key === 'PAN')
    case 'spain':
      return countries.find(c => c.key === 'ESP')

    default:
      return countries[0]
  }
}

export const validateSelectTaxes = ({ values, country, isMultitax }) => {
  if (!isArray(values))
    return values;

  if (isMultitax && [COUNTRIES.MEXICO, COUNTRIES.COLOMBIA].includes(country)) {
    if (values.length > 0) {
      const lastSelected = values[values.length - 1];
      const lastSelectedType = get(lastSelected, 'type', null);

      if (lastSelectedType === "IEPS" || lastSelectedType === "IVA") {
        const filteredValues = values.filter((tax) => get(tax, "type", "") !== lastSelectedType);
        return [...filteredValues, lastSelected];
      }
    }
  }

  return values
}

export const warehouseInfoLink = (country) => {
  switch (country) {
    case 'argentina':
      return 'https://ayuda.alegra.com/es/configur%C3%A1-las-terminales-para-facturar-en-el-punto-de-venta-pos-argentina'
    default:
      return 'https://ayuda.alegra.com/es/configura-terminales-para-facturar-en-el-sistema-punto-de-venta-pos'
  }
}

export const isClothingStore = (country, origin) => {
  const originBoutiques = `pos-boutiques`;
  return (country === "colombia" && origin === originBoutiques);
}

export const renderProductKeyTooltip = (country) => {
  switch (country) {
    case 'colombia':
      return (<p className='typography caption-bold variant-inverse'>
        {replaceAndParse(I18n.get('productKeyHelp', 'Es un campo obligatorio si generas facturas electrónicas, conoce {} a tus productos.'), [
          `<a href="https://ayuda.alegra.com/es/agrega-el-c%C3%B3digo-de-producto-a-tus-%C3%ADtems-de-venta-creados-en-alegra-pos-colombia" target="_blank" rel="noreferrer">
            ${I18n.get('productKeyHelp1', 'cómo agregar los códigos')}
          </a>`
        ])}
      </p>)
    case 'costaRica':
      return (<p className='typography caption-bold variant-inverse'>
        {replaceAndParse(I18n.get('productKeyHelp', 'Conoce los {} para los productos y servicios'), [
          `<a href="https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9" target="_blank" rel="noreferrer">
            ${I18n.get('productKeyHelp1', 'códigos definidos en el Cabys')}
          </a>`
        ])}
      </p>)
    case 'peru':
      return (<p className='typography caption-bold variant-inverse'>
        {replaceAndParse(I18n.get('productKeyHelp', 'Conoce {} de SUNAT a tus productos y servicios'), [
          `<a href="https://ayuda.alegra.com/es/crea-tus-productos-y-servicios-con-el-c%C3%B3digo-sunat-para-facturar-desde-el-punto-de-venta" target="_blank" rel="noreferrer">
              ${I18n.get('productKeyHelp1', 'cómo agregar el código')}
            </a>`
        ])}
      </p>)
    case 'mexico':
      return (<p className='typography caption-bold variant-inverse'>
        {replaceAndParse(I18n.get('productKeyHelp', 'Conoce {} del SAT a tus productos y servicios.'), [
          `<a href="http://200.57.3.89/PyS/catPyS.aspx" target="_blank" rel="noreferrer">
            ${I18n.get('productKeyHelp1', 'cómo agregar la clave')}
          </a>`
        ])}
      </p>)
    default:
      return null;
  }
}

export const downloadExportableReport = (data, reportName, extension) => {
  const buffer = Buffer.from(data, 'base64')

  let url = window.URL.createObjectURL(
    new Blob([buffer], {
      type: "text/plain;charset=UTF-8"
    })
  )

  let today = new Date().toISOString().split("T")[0].split("-")

  const link = document.createElement("a")
  link.href = url
  link.setAttribute(
    "download",
    `${reportName}-${today[2]}-${today[1]}-${today[0]}.${extension}`
  )

  document.body.appendChild(link)
  link.click()
}

export const getHoursBetweenDates = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);

  return (date2.getTime() - date1.getTime()) / (1000 * 3600);
}

export const getLocalDate = (date, country) => {
  switch (country) {
    case 'panama':
      return date.toLocaleString('en-US', { timeZone: 'America/Panama' });
    default:
      return date.toLocaleString();
  }
}

export const getWarehouseHelpUrl = (country) => {
  switch (country) {
    case "argentina":
      return "https://ayuda.alegra.com/es/gestion%C3%A1-tu-inventario-en-diferentes-dep%C3%B3sitos-con-alegra-punto-de-venta-argentina"
    case "mexico":
    case "republicaDominicana":
    case "peru":
    case "spain":
      return "https://ayuda.alegra.com/es/gestiona-tu-inventario-en-diferentes-almacenes-en-el-sistema-punto-de-venta-software-tpv"
    default:
      return "https://ayuda.alegra.com/es/gestiona-tu-inventario-en-diferentes-bodegas-en-el-sistema-punto-de-venta-pos"
  }
}

export const getInventoryHelpUrl = (country) => {
  switch (country) {
    case "mexico":
      return "https://ayuda.alegra.com/es/crea-y-gestiona-tus-productos-desde-tu-software-tpv-m%C3%A9xico"
    case "costaRica":
      return "https://ayuda.alegra.com/es/crea-y-gestiona-tus-productos-desde-el-sistema-punto-de-venta-pos-costa-rica"
    case "argentina":
      return "https://ayuda.alegra.com/es/cre%C3%A1-y-gestion%C3%A1-tus-productos-desde-el-punto-de-venta-pos-argentina"
    case "colombia":
      return "https://ayuda.alegra.com/es/agrega-el-c%C3%B3digo-de-producto-a-tus-%C3%ADtems-de-venta-creados-en-alegra-pos-colombia"
    case "peru":
      return "https://ayuda.alegra.com/es/crea-tus-productos-y-servicios-con-el-c%C3%B3digo-sunat-para-facturar-desde-el-punto-de-venta"
    default:
      return "https://ayuda.alegra.com/es/crea-y-gestiona-tus-productos-desde-el-sistema-punto-de-venta-pos"
  }
}

export const supportGroups = {
  group1: {
    countries: ["costaRica", "panama"],
    deadline: "2023-06-07"
  }
}

export const isAvailableDate = (date) => {
  const dateToEnd = dayjs(date);
  const dateNow = dayjs().format("YYYY-MM-DD");
  const isAvailableDate = dateToEnd.diff(dateNow, 'd') <= 0;
  return isAvailableDate;
}

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const base64ToBlob = (base64, mimeType = '') => {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeType });
}

export const getInitialState = (stateName, defaultState) => {
  const globalState = store.get('globalState');

  if (globalState === null)
    return defaultState;

  if (availableIntermittenceCompanies.includes(get(globalState, 'auth.company.id', '')) 
    || availableIntermittenceCountries.includes(get(globalState, 'auth.company.applicationVersion')))
    return get(store.get('globalState'), stateName, defaultState);
  
  return defaultState;
}

export { toast };
export { fetch };