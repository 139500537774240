import BigNumber from 'bignumber.js';
import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';

export const validate = (values, { total, tip, step, shiftOpen, shiftsEnabled, shiftCash, stationCashBank, itemsInRefund, isAllItemsPupaleted }) => {
  let errors = {};  
  const method = get(values, 'refund.method.value');
  const invoiceBalance = get(values, 'refund.document.balance', 0);

  if (step === 1 || step === 2) {
    if (!get(values, 'refund.client'))
      errors.refund = {
        ...errors.refund,
        client: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }
    if (!get(values, 'refund.numeration'))
      errors.refund = {
        ...errors.refund,
        numeration: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }
    if (!get(values, 'refund.date'))
      errors.refund = {
        ...errors.refund,
        date: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }

    if (method === 'creditToSales') {
      const invoiceIsElectronic = get(values, 'refund.document.stamp') !== null;
      if (!get(values, 'refund.document'))
        errors.refund = {
          ...errors.refund,
          document: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        }
      if (invoiceIsElectronic && !get(values, 'refund.creditNoteType'))
        errors.refund = {
          ...errors.refund,
          typeCredit: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        }
    }
  }

  if (step === 3) {
    if (method === 'creditToSales') {
      if (!isAllItemsPupaleted)
        errors.refund = {
          ...errors.refund,
          itemsInRefund: I18n.get('', 'Todos los items deben estar buscados en Alegra')
        }
      
      if (new BigNumber(invoiceBalance).lt(new BigNumber(total)))
        errors.refund = {
          ...errors.refund,
          amount: I18n.get('refundTotalExceedsBalance', 'El total a devolver debe ser menor o igual al valor pendiente por cobrar.')
        }
    }

    if (itemsInRefund?.length) {
      const inactiveItems = itemsInRefund.filter(item => item.status === 'inactive');
      if (inactiveItems.length)
        errors.refund = {
          ...errors.refund,
          itemsInRefund: I18n.get('', 'No puedes continuar con items inactivos')
        }
    }
    
    if (!itemsInRefund?.length)
      errors.refund = {
        ...errors.refund,
        itemsInRefund: I18n.get('', 'Debes elegir al menos un item')
      }
  }

  if (step === 4) {
    let refundAmount = new BigNumber(!!get(values, 'refund.amount') ? get(values, 'refund.amount') : 0)
    let invoiceAmount = new BigNumber(!!get(values, 'invoice.amount') ? get(values, 'invoice.amount') : 0)
    const refundAccountId = get(values, 'refund.bank.id');
    const stationCashBankId = get(stationCashBank, 'id');
    
    if (!get(values, 'refund.amount'))
      errors.refund = {
        ...errors.refund,
        amount: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }

    if (method === 'creditToSales') {
      if (new BigNumber(invoiceBalance).lt(new BigNumber(refundAmount)))
        errors.refund = {
          ...errors.refund,
          amount: I18n.get('refundAmountExceedsBalance', 'El monto a devolver excede el saldo de la factura')
        }
    }

    if (method === 'cash') {
      const isEq = new BigNumber(total).eq(new BigNumber(refundAmount))

      if (!get(values, 'refund.bank'))
        errors.refund = {
          ...errors.refund,
          bank: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        }

      if (!!shiftsEnabled && shiftOpen === true && refundAccountId === stationCashBankId && refundAmount.gt(shiftCash)) 
        errors.refund = { 
          ...errors.refund, 
          amount: I18n.get('noCashAvailable', 'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja')
        }
      
      if (!isEq)
        errors.refund = {
          ...errors.refund,
          amount: I18n.get('refundErrorTotalAmount', 'El monto asociado a las ventas debe ser igual al monto total de la nota de crédito')
        }
      
    }

    if (method === 'combined') {
      const documentHaveTip = !!get(values, 'refund.document.additionalCharges[0]', false);
      const tipInclude = !!get(tip, 'include', false);

      if (!documentHaveTip && tipInclude)
        errors.refund = {
          ...errors.refund,
          document: I18n.get('tipError', 'No puedes agregar propina si el documento no tiene propina')
        }
        console.log(errors.refund)
      if (!get(values, 'refund.bank'))
        errors.refund = {
          ...errors.refund,
          bank: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        }

      if (!get(values, 'refund.document'))
        errors.refund = {
          ...errors.refund,
          document: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        }

        if (!get(values, 'invoice.amount'))
        errors.invoice = {
          ...errors.invoice,
          amount: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        }
  
        if (!!shiftsEnabled && shiftOpen === true && refundAmount.gt(shiftCash)) {
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('noCashAvailable', 'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja')
          }
        }
  
        if (!!get(values, 'refund.document')) {
          if (new BigNumber(invoiceBalance).lt(invoiceAmount)){
            errors.invoice = {
              ...errors.invoice,
              amount: I18n.get('refundAmountExceedsInvoiceAmount', 'El monto a devolver excede el monto de la factura')
            }
          }

          if (new BigNumber(total).lt(refundAmount.plus(invoiceAmount))){
            errors.refund = {
              ...errors.refund,
              amount: I18n.get('theCombinedAmountCannotBeGreaterThanRefundTotal', 'el monto combinado no puede ser mayor que el total de la devolución')
            }
    
            errors.invoice = {
              ...errors.invoice,
              amount: I18n.get('theCombinedAmountCannotBeGreaterThanRefundTotal', 'el monto combinado no puede ser mayor que el total de la devolución')
            }
          }
        }
    }

  }

  return errors;
};

export const transform = (values, { tip, decimal, subtotal }) => {
  let transformedValues = {
    invoices: [],
    refunds: []
  }
  const method = get(values, 'refund.method.value');

  if (method === 'cash' || method === 'combined') {
    transformedValues = {
      ...transformedValues,
      refunds: [{
        amount: +get(values, 'refund.amount', 0),
        account: get(values, 'refund.bank.id', null),
        observations: get(values, 'refund.observations', null),
      }]
    }
  }

  if (method === 'creditToSales') {
    transformedValues = {
      ...transformedValues,
      stamp: {
        generateStamp: !!get(values, 'refund.document.numberTemplate.isElectronic', false)
      },
      invoices: [{
        ...get(values, 'refund.document', null),
        amount: +get(values, 'refund.amount', 0),
      }],
    }
  }

  if (method === 'combined') {
    transformedValues = {
      ...transformedValues,
      invoices: [{
        ...get(values, 'refund.document', null),
        amount: +get(values, 'invoice.amount', 0),
      }]
    }
  }

  if (get(tip, "include", false)) {
    const additionalCharge = get(values, 'refund.document.additionalCharges[0]', null);
    let amount = 0;

    if (get(tip, "type", 'VALUE') === 'VALUE') {
      amount = new BigNumber(get(tip, "value", 0)).decimalPlaces(decimal).toNumber()
    } else {
      amount = subtotal.multipliedBy(get(tip, "percentage", 10)).dividedBy(100).decimalPlaces(decimal)
    }

    transformedValues = {
      ...transformedValues,
      additionalCharges: [{
        id: get(additionalCharge, 'idCharge', null),
        amount: amount
      }]
    }
  }
  
  transformedValues = {
    ...transformedValues,
    type: get(values, 'refund.creditNoteType.key', null),
    note: get(values, 'refund.note', null),
    cause: get(values, 'refund.cause', null),
    numeration: get(values, 'refund.numeration', null),
  }

  return transformedValues
};

export const getPrevStep = (step, method) => {
  if (step === 3 && method !== 'creditToSales') return 1;
  return step - 1;
}
