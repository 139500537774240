import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import Client from './Client';
import Company from './Company';
import Logo from './Logo';
import { printSettings } from '../../../selectors/company';
import RefundInfo from './RefundInfo';
import CustomPhrase from './CustomPharese';

import { logo as logoSelector } from '../../../selectors/company';
import Items from './ListItems';
import { get } from 'lodash';
import LastRow from './LastRow';
import Cude from './Cude';
import Invoice from './Invoice';
import Notes from './Notes';
import Payments from './Payments';
import Electronic from './Electronic';
import RefundType from './RefundType';

const getTypeInvoice = (refund) => {
  const documentType = get(refund, 'numberTemplate.documentType', null);

  if (documentType === 'creditNote') {
    return I18n.get('invoice', 'Factura');
  }

  if (documentType === 'incomeAdjustmentNote') {
    return I18n.get('document', 'Documento');
  }
};

const PrintRefund = ({ refund }) => {
  const companySetting = useSelector(printSettings);
  const { imageOrQr } = companySetting;
  const isElectronic = !!get(refund, 'numberTemplate.isElectronic', null);
  const companyLogo = useSelector(logoSelector);
  const items = get(refund, 'items', null);
  const invoices = get(refund, 'invoices', null);
  const invoiceType = getTypeInvoice(refund);
  const cude = get(refund, 'stamp.cufe', null);
  const customPhrase = get(companySetting, 'customPhrase', null);
  const note = get(refund, 'note', null);

  const tip = get(refund, 'additionalCharges[0].amount', 0);

  return (
    <div
      id='print-invoice'
      className={`print-${companySetting.format} d-flex flex-column overflow-hidden`}
      style={{
        paddingLeft: `${companySetting.leftMargin}mm`,
        paddingRight: `${companySetting.rightMargin}mm`,
      }}
    >
      <Logo companyLogo={companyLogo} setting={companySetting} />
      {!!isElectronic && <Electronic refund={refund} setting={companySetting} />}
      <Company
        companyLogo={companyLogo}
        imageOrQr={imageOrQr}
        setting={companySetting}
      />
      <Client refund={refund} setting={companySetting} />
      <RefundInfo refund={refund} setting={companySetting} />
      <Items items={items} setting={companySetting} />
      <Payments tip={tip} items={items} setting={companySetting} />
      <Notes notes={note} />
      {!!isElectronic && <Cude refund={refund} cude={cude} />}
      {
        !!invoices && invoices.map(invoice => <Invoice key={invoice.id} invoice={invoice} invoiceType={invoiceType} isElectronic={isElectronic} />)
      }
      <RefundType refund={refund} isElectronic={isElectronic} />
      <CustomPhrase customPhrase={customPhrase} />
      <LastRow />
    </div>
  );
};

export default PrintRefund;
