import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { get, capitalize, toLower, some } from 'lodash'

import { formError } from '../../../utils/errors';
import { currency, client as clientSelector } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'
import { checkStationValues } from '../../../reducers/app';
import { closeModal, openModal } from '../../../reducers/modals';
import { payInvoice, clear, setSettings } from '../../../reducers/activeInvoice';
import { total, numeration as invoiceNumerationSelector, items as itemsSelector } from '../../../selectors/activeInvoice';
import {
  station as stationSelector,
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  stationInvoiceNumeration,
  stationSeller,
  electronicInvoicing,
  stationCashBank,
} from '../../../selectors/app';
import {
  isOnlyInvoicingPlan,
  country as countrySelector,
  anotation as companyAnotation,
  decimalPrecision
} from '../../../selectors/company';
import { BasicData, AdvancedData } from '../../forms/invoice/Invoice';
import { validate, transform, validationPreviousCreation } from '../../forms/invoice/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Total from './Total';
import Change from './Change';
import Methods from './Methods';
import { replaceAndParse, toast } from '../../../utils';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { COUNTRIES } from '../../../utils/enums/countries';
import { Tooltip } from '@alegradev/smile-ui-react';
import { economicActivitiesSelector } from '../../../selectors/auth';
import { allTotalToCollectData } from '../../../selectors/totalToCollect';
import ReportsAPI from '../../../reducers/balanceByClient';
import { addTotalToCollect } from '../../../reducers/totalToCollect';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import CreditLimitNotification from '../../forms/invoice/CreditLimitNotification';
import { showCreditLimitField } from '../../../pages/contacts/utils/limitCredit';

const Invoice = () => {
  const connectionStatus = useConnectionStatus();
  const [isTotalToCollectLoading, setIsTotalToCollectLoading] = useState(false);
  const [totalToCollect, setTotalToCollect] = useState(0);
  const [canCredit, setCanCredit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeNequiPush, setActiveNequiPush] = useState(true);
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.invoice.isOpen', false));
  const station = useSelector(stationSelector);
  const totalPrice = useSelector(total);
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan)
  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const aditionalSettings = useSelector(
    (state) => state.aditionalSettings.settings
  );
  const economicActivities = useSelector(economicActivitiesSelector);
  const mainNumeration = useSelector(stationInvoiceNumeration)
  const invoiceNumeration = useSelector(invoiceNumerationSelector)
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const defaultAnotation = useSelector(companyAnotation);
  const client = useSelector(clientSelector);
  const items = useSelector(itemsSelector);
  const synchronizingClient = useSelector(state => get(state, 'clients.synchronizingClient', false))
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const allTotalToCollect = useSelector(allTotalToCollectData);

  const currencyCode = !!get(selectedCurrency, 'code') ? get(selectedCurrency, 'code')
    : !!get(mainCurrency, 'code') ? get(mainCurrency, 'code') : ''
  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const cashBank = useSelector(stationCashBank);
  const debitBank = useSelector(stationDebitBank)
  const creditBank = useSelector(stationCreditBank)
  const transferBank = useSelector(stationTransferBank)
  const seller = useSelector(stationSeller)

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const submit = async values => {
    const validation = validationPreviousCreation({ ...values, country, client, numeration, items });
    if (validation.hasError) {
      validation.errors.forEach(err => {
        toast[err.type]({ ...err })
      })
      await sleep(100)
      return
    }

    try {
      setLoading(true)
      const canContinue = await dispatch(checkStationValues({ type: 'invoice', values }))
      if (!canContinue)
        return;

      const transformedValues = transform(values, country);
      await dispatch(payInvoice(transformedValues))

      dispatch(closeModal({ modal: 'invoice' }))
      if (!(!!pendingInvoicesEnabled && !onlyInvoicingPlan)) {
        dispatch(clear())
        if (country === COUNTRIES.COSTA_RICA && !!aditionalSettings['economicActivity']) {
          const economicActivity = economicActivities.find(
            (activity) => activity.main === true
          );
          dispatch(setSettings({ economicActivity }))
        }
      }
      dispatch(openModal({ modal: 'invoiceSaved' }))
    } catch (error) {
      setLoading(false)
      return formError(error, I18n.get('createInvoiceError', 'hubo un error en la creación de la factura'), country)
    }
    setLoading(false)
  }

  const getSubmitButtonName = (values) => {
    let buttonName = I18n.get('checkIn', 'facturar')

    if (country === 'costaRica')
      buttonName = get(numeration, 'documentType') === 'invoice' ? buttonName : I18n.get('sell', 'Vender')

    if (country === 'colombia' && (get(numeration, 'prefix') === 'EPOS' || !!get(numeration, 'linkedDevices', null)))
      buttonName = I18n.get('sell', 'vender')

    if (["bancolombiaQR", "nequiPush"].includes(get(values, 'method')))
      buttonName = I18n.get("savePayment", "Guardar pago")

    if(country === 'spain'){
      buttonName = I18n.get("sell", "Vender")
    }

    if (!!values.transfer)
      buttonName = I18n.get("savePayment", "Guardar pago")

    if (!!isElectronic)
      buttonName = I18n.get('emit', 'Emitir')

    if(country === COUNTRIES.MEXICO && get(numeration, 'documentType') === 'invoice')
      buttonName = I18n.get('stamp', 'Timbrar')

    return buttonName;
  }

  const getModalTitle = () => {
    switch (country) {
      case 'colombia':
        if (numeration?.documentType === 'saleTicket' && numeration?.isElectronic)
          return I18n.get(`invoiceModalTitle.electronicPOSDocument`, 'documento POS electrónico')
        if (numeration?.documentType === 'saleTicket')
          return I18n.get(`invoiceModalTitle.saleTicket`, 'documento POS')
        return toLower(I18n.get(`invoiceModalTitle.${numeration?.documentType}`, "factura"))
      case 'spain':
        if (numeration?.documentType === 'saleTicket')
          return toLower(I18n.get(`invoiceModalTitle.saleTicket`, 'ticket'));
        return toLower(
          I18n.get(`invoiceModalTitle.${numeration?.documentType}`, 'factura')
        );

      default:
        return toLower(I18n.get(`invoiceModalTitle.${numeration?.documentType}${(numeration?.isElectronic && ["panama", "republicaDominicana"].includes(country)) ? ".electronic" : ""}`, 'factura'))
    }
  }

  const onCloseModal = async () => {
    dispatch(closeModal({ modal: 'invoice' }))
  }

  const getLocalBalance = (clientId) => {
    const foundItem = allTotalToCollect.find((item) => item.id === clientId);
    return foundItem ? foundItem.totalToCollect : null;
  };

  const getBalanceByClient = async () => {
    setIsTotalToCollectLoading(true);

    try {
      let amount = null;
      const clientId = get(client, 'id', null);

      if (connectionStatus) {
        try {
          const response = await ReportsAPI.get(
            `client_id=${clientId}&balance_type=totalToCollect`
          );
          amount = get(response, 'data.totalToCollect', null);

          dispatch(
            addTotalToCollect({
              id: clientId,
              totalToCollect: amount,
            })
          );
        } catch (apiError) {
          console.error('Error al consultar la API:', apiError);
          amount = getLocalBalance(clientId);
        }
      } else {
        amount = getLocalBalance(clientId);
      }

      setTotalToCollect(amount);
    } catch (error) {
      console.error('Error en el proceso:', error);
    } finally {
      setIsTotalToCollectLoading(false);
    }
  };

  useEffect(() => {
    if (showCreditLimitField(country) && isOpen) {
      getBalanceByClient();
    }
  }, [isOpen]);

  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => loading ? null : onCloseModal()}
      hideClose={loading}
      title={replaceAndParse(I18n.get('payAnyDocument', 'pagar {}'), [getModalTitle()])}
      className="modal__invoice"
    >
      <Form
        onSubmit={submit}
        validate={values => validate({ ...values, country, decimal, isDecimalActive }, {
          total: totalPrice,
          country
        })}
        initialValues={{
          cashBank,
          debitBank,
          creditBank,
          transferBank,
          numeration,
          seller,
          anotation: defaultAnotation,
          paymentMethod: { value: 'CASH', label: capitalize(I18n.get("paymentForm.Cash", "Contado")) },
          email: get(client, 'email', null),
          phone: get(client, 'mobile', null)
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, error, pristine, submitting, submitError, submitFailed }) => {
          const anotationMaxLimit = country === 'republicaDominicana' && isElectronic && get(values, 'anotation.length', 0) > 250
          return (
            <form noValidate data-testid="form-check-in" onSubmit={handleSubmit}>
              <div className="d-flex flex-column p-3 modal__invoice-body">
                <Total values={values} form={form} total={totalPrice} />

                {!!values.method && <Change values={values} total={totalPrice} />}

                {(values.method === 'nequiPush' && !values.transfer) && (
                  <div className='modal__invoice-segmented-control p-2 mb-3'>
                    <div
                      className={`w-100 modal__invoice-segmented-control-${activeNequiPush ? 'active' : 'inactive'}`}
                      onClick={() => setActiveNequiPush(true)}
                    >
                      <p className='h4 inter-regular m-0 p-0'>{I18n.get("sendRequestToApp", "Enviar solicitud a la app")}</p>
                    </div>
                    <div className={`w-100 modal__invoice-segmented-control-${!activeNequiPush ? 'active' : 'inactive'}`}
                      onClick={() => setActiveNequiPush(false)}
                    >
                      <p className='h4 inter-regular m-0 p-0'>{I18n.get("scanQRCode", "Escanear código QR")}</p>
                    </div>
                  </div>
                )}
                <div className={`flex-column flex-sm-row ${!values.method ? 'd-none' : 'd-flex'}`}>
                  <div className="w-100 modal__invoice-basic-data">
                    <BasicData
                      values={values}
                      form={form}
                      total={totalPrice}
                      fmt={fmt}
                      currencyCode={currencyCode}
                      activeNequiPush={activeNequiPush}
                    />
                  </div>

                  <div className="w-100 modal__invoice-advanced-data">
                    <AdvancedData form={form} values={values} />
                  </div>
                </div>
                <div className={!values.method || !showCreditLimitField(country) ? 'd-none' : ''}>
                  <CreditLimitNotification client={client} values={values} total={totalPrice} totalToCollect={totalToCollect} setCanCredit={setCanCredit}/>
                </div>
                <div className={!!values.method ? 'd-none' : ''}>
                  <Methods form={form} total={totalPrice} />
                </div>
              </div>

              {(!!error && !!submitFailed) && (
                <Notification isOpen={true} text={replaceAndParse(error)} type="error" />
              )}

              {(!!submitError && !!submitFailed) && (
                <Notification isOpen={true} text={replaceAndParse(submitError)} type="error" />
              )}

              <Tooltip
                visible={synchronizingClient && isElectronic}
                children={
                  <Bottom
                    hideRequired
                    hideCancel={loading || !!values.transfer}
                    onClose={() => dispatch(closeModal({ modal: 'invoice' }))}
                    submitText={getSubmitButtonName(values)}
                    disabled={some(values?.decimalsError, (value) => value) ||isTotalToCollectLoading || submitting || pristine || (synchronizingClient && isElectronic) || anotationMaxLimit || (["bancolombiaQR", "nequiPush"].includes(get(values, 'method')) && !values.transfer) || !canCredit}
                    submitting={submitting || (synchronizingClient && isElectronic)}
                  />
                }
                overlay={I18n.get('clientIsSyncing', 'El cliente se está sincronizando')}
              />
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}

export default Invoice;