import { I18n } from '@aws-amplify/core';
import { get, capitalize, isArray } from 'lodash';
import dayjs from 'dayjs';
import { COUNTRIES } from '../../../utils/enums/countries';

export const validate = (
  values,
  { country, customFields, regime, isElectronic }
) => {
  let errors = {};

  const type = get(values, 'type', '');
  const name = get(values, 'name', '');
  const price = get(values, 'price', '');
  const salePrice = get(values, 'salePrice', '');

  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));
  if (!price)
    errors.price = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  if (!salePrice)
    errors.salePrice = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (!get(values, 'unit.key', null))
    errors.unit = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (type === 'product') {
    if (!get(values, 'initialQuantity', null))
      errors.initialQuantity = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );

    if (!get(values, 'warehouse.id', null))
      errors.warehouse = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );

    if (!get(values, 'unitCost', null))
      errors.unitCost = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (country === 'costaRica') {
    if (isElectronic && !get(values, 'productKey.key', null))
      errors.productKey = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );

    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length < 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 12.';
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length > 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.';
  }

  if (country === 'mexico') {
    if (isElectronic && !get(values, 'productKey.key', null))
      errors.productKey = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (country === 'republicaDominicana') {
    const DOMINICAN_NAME_MAX_LENGTH = 80;
    if (name?.length > DOMINICAN_NAME_MAX_LENGTH) {
      errors.name = capitalize(
        I18n.get(
          'maxLengthError',
          `Usa máximo ${DOMINICAN_NAME_MAX_LENGTH} caracteres`
        )?.replace('{{maxCharacters}}', DOMINICAN_NAME_MAX_LENGTH)
      );
    }
  }

  customFields.map((field) => {
    const value = get(values, `customFields.cf${get(field, 'id')}`);
    const isBoolean = get(field, 'type') === 'boolean';
    if (!isBoolean && !value) {
      errors = {
        ...errors,
        customFields: {
          ...errors.customFields,
          [`cf${get(field, 'id')}`]: capitalize(
            I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          ),
        },
      };
    }
    return null;
  });

  return errors;
};

const dynamicFieldTransform = (value, type) => {
  switch (type) {
    case 'int':
      return !!+value ? +value : null;
    case 'decimal':
      return !!+value ? +value : null;
    case 'boolean':
      return !!value;
    case 'date':
      return dayjs(value).format('YYYY-MM-DD');
    case 'optionsList':
      return get(value, 'value');
    default:
      return value;
  }
};

export const transform = (values, { country, customFields }) => {
  let taxes = !!get(values, 'tax', null) ? get(values, 'tax') : [];
  taxes = !isArray(taxes) ? [taxes] : taxes;

  taxes = taxes.filter((tax) => get(tax, 'id') !== -1);

  let countryHasTaxCondition = country === 'mexico';
  let taxCondition = countryHasTaxCondition
    ? get(taxes, '[0].customTax', false)
      ? { value: get(taxes, '[0].id') }
      : { value: '02' }
    : null;

  const price = get(values, 'salePrice', '0');
  // const type = get(values, 'type');

  const type = get(
    values,
    'type',
    country === 'republicaDominicana' ? 'product' : 'simple'
  );
  const itemType =
    type !== 'kit'
      ? get(values, 'hasVariants', false)
        ? 'variantParent'
        : type
      : 'kit';

  return {
    name: get(values, 'name', ''),
    // type: type === 'kit' ? 'kit' : type === 'service' ? 'service' : 'simple',
    type: itemType,
    tax: countryHasTaxCondition
      ? taxCondition && get(taxCondition, 'value') === '02'
        ? !!isArray(taxes) && taxes.length > 0
          ? taxes.map((tax) => tax.id)
          : null
        : []
      : !!isArray(taxes) && taxes.length > 0
      ? taxes.map((tax) => tax.id)
      : null,
    inventory:
      type === 'product'
        ? {
            unit: get(values, 'unit.key'),
            unitCost: get(values, 'unitCost'),
            warehouses: [
              {
                id: get(values, 'warehouse.id'),
                initialQuantity: get(values, 'initialQuantity'),
              },
            ],
          }
        : country === 'mexico' ||
          country === 'peru' ||
          country === 'costaRica' ||
          country === 'argentina' ||
          country === 'colombia'
        ? { unit: 'service' }
        : null,
    price: [{ price }],
    customFields: customFields.map((field) => ({
      id: get(field, 'id'),
      name: get(field, 'name'),
      type: get(field, 'type'),
      value: dynamicFieldTransform(
        get(values, `customFields.cf${get(field, 'id')}`),
        get(field, 'type')
      ),
    })),
    productKey: get(values, 'productKey', null),
    taxCondition,
    predial: country === 'mexico' ? get(values, 'predial', null) : null,
  };
};

export const getTaxesLabel = (country, tax) => {
  if (country === COUNTRIES.COLOMBIA) {
    if (get(tax, 'rate') === 'EXCLUDED') {
      return !!tax.customName
        ? tax.customName
        : `${tax.name}-Excl (${tax.percentage}%)`;
    } else if (get(tax, 'rate') === 'EXEMPT') {
      return !!tax.customName
        ? tax.customName
        : `${tax.name}-Exen (${tax.percentage}%)`;
    }
  }
  return !!tax.customName ? tax.customName : `${tax.name} (${tax.percentage}%)`;
};