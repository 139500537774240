import React from 'react'
import { I18n } from '@aws-amplify/core';

import Methods from './Methods';

const RefundMethods = ({ onChooseMethod }) => {
  return (
    <div className='refund-choose-method'>
      <p className='subtitle-refund-method'>
        {I18n.get('chooseRefundMethodNew', 'Elige el tipo de devolución que le vas a registrar a tu cliente:')}
      </p>

      <div>
        <Methods onChooseMethod={onChooseMethod}/>
      </div>
    </div>
  )
}

export default RefundMethods;