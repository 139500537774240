import { get, isString } from 'lodash'
import { I18n } from 'aws-amplify';
import { isUserElegibleForNewRefund } from '../refunds/utils';

export const getFmt = (invoice, mainCurrency, decimalSeparator = '.') => {
  const prefix = !!get(invoice, 'currency.symbol')
    ? get(invoice, 'currency.symbol')
    : !!get(mainCurrency, 'symbol')
      ? get(mainCurrency, 'symbol')
      : '';

  return {
    prefix,
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  };
}

export const getItemReference = (item, offline) => {
  try {
    return !!offline
      ? !!get(item, 'reference.reference')
        ? get(item, 'reference.reference') : !!get(item, 'reference')
          ? get(item, 'reference') : ''
      : !!get(item, 'reference.reference')
        ? JSON.parse(get(item, 'reference.reference')) : !!get(item, 'reference')
          ? JSON.parse(get(item, 'reference')) : ''
  } catch {
    return ''
  }
}

export const getLegalStatusKey = (invoice, numerations = [], country) => {
  const isDocEq = get(invoice, 'numberTemplate.documentType') === 'saleTicket'
  const hasItemsWithNoIvaDays = get(invoice, 'saleTicketHasItemsWithNoIvaDays') === 'yes'
  const legalStatus = get(invoice, 'stamp.legalStatus', null);
  const invoiceNumeration = numerations.find(n => n.id === get(invoice, 'numberTemplate.id'));
  const isPOSElectronicDocument = get(invoiceNumeration, 'documentType') === 'saleTicket' && get(invoiceNumeration, 'isElectronic');

  if (isPOSElectronicDocument && (legalStatus === 'STAMPED_AND_ACCEPTED' || legalStatus === 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS'))
    return 'legalStatus.issued.alter';


  if (isDocEq && hasItemsWithNoIvaDays && !!legalStatus)
    return 'legalStatus.issued'
  if (isDocEq && hasItemsWithNoIvaDays && !legalStatus)
    return 'legalStatus.contingency'
  if (invoiceNumeration && invoiceNumeration.isElectronic && !legalStatus && country === 'panama')
    return 'legalStatus.inProcess';
  if (invoiceNumeration && invoiceNumeration.isElectronic && !legalStatus)
    return 'legalStatus.toBeIssued'

  switch (legalStatus) {
    case 'ACCEPTED':
    case 'ACCEPTED_WITH_OBSERVATIONS':
    case 'PENDING':
      return 'legalStatus.toBeIssued'
    case 'STAMPED_AND_ACCEPTED':
    case 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS':
      return 'legalStatus.issued';
    case 'REJECTED':
    case 'STAMPED_AND_REJECTED':
      return 'legalStatus.rejected';
    case 'STAMPED_AND_WAITING_RESPONSE':
      return 'legalStatus.inProcess';
    default:
      return legalStatus;
  }
}

export const legalStatusToBadgeStatus = (status, country) => {
  switch (status) {
    case 'legalStatus.issued':
    case 'legalStatus.issued.alter':
      return 'closed';
    case 'legalStatus.rejected':
      return 'open';
    case 'legalStatus.toBeIssued':
      return 'void';
    case 'legalStatus.inProcess':
      return country === 'panama' ? 'process' : 'void';
    default:
      return 'void';
  }
}

export const renderClientIdentification = client => {
  if (!client) return ''

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`
    return ` (${get(client, 'identification.number')})`
  }
  return ''
}

export const renderClientName = client => {
  if (!client) return '';

  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name')))
      return get(client, 'name')
    return `${get(client, 'name.firstName', '')}${!!get(client, 'name.secondName', null)
      ? ' ' + get(client, 'name.secondName') : ''}${!!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName') : ''}`;
  }
  return ''
}

export const getVoidTitle = (invoice, country) => {
  const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');
  let res = I18n.get('void', 'anular');
  switch (country) {
    case 'colombia':
      if (get(invoice, 'numberTemplate.documentType') === 'saleTicket' && numerationIsElectronic)
        res = I18n.get('applyRefund.adjustmentNote', 'Aplicar nota de ajuste')
      if (get(invoice, 'numberTemplate.documentType') === 'invoice' && numerationIsElectronic)
        res = I18n.get('applyRefund.creditNote', 'Aplicar nota credito')
      break;
  }
  return res;
}

export const isElegibleForApplyRefund = (invoice, country, company) => {
  const numberTemplateDocumentType = get(invoice, 'numberTemplate.documentType');
  const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');
  const paidTotally = get(invoice, 'status') === 'closed';

  if (country === 'colombia') {
    // check for deployGroups
    const userElegible = isUserElegibleForNewRefund(country, company)
    const isInvoiceOrSaleTicketElectronic = numerationIsElectronic && (numberTemplateDocumentType === 'invoice' || numberTemplateDocumentType === 'saleTicket')

    // check for POS tickets or electronic invoices
    if (userElegible && !paidTotally && isInvoiceOrSaleTicketElectronic && numerationIsElectronic)
      return true;
  }

  return false;
}

export const showVoidIcon = (invoice, country, company) => {
  if (country === 'colombia') {
    return isElegibleForApplyRefund(invoice, country, company)
  }
  return false;
}