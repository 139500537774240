import { useSelector } from "react-redux";
import { useFormat } from "../../../../hooks/useFormat";
import { Cash } from "./Cash";
import { CreditToSales } from "./CreditToSales";
import { Combined } from "./Combined";
import { total as totalSelector } from '../../../../selectors/activeRefund';
import { I18n } from '@aws-amplify/core';
import { toUpper } from "lodash";

export const LastStepForm = ({ method }) => {
  const { decimal, fmt } = useFormat();
  const total = useSelector(totalSelector);
  const formatedTotal = total.toFormat(decimal, fmt);

  return (
    <div className="my-4">
      <div className="d-flex mb-5 refund-total">
        <h4 className="m-auto">{toUpper(I18n.get('total', 'Total'))}<span className="ml-2">{formatedTotal}</span></h4>
      </div>
        {method === 'cash' && <Cash />}
        {method === 'creditToSales' && <CreditToSales />}
        {method === 'combined' && <Combined />}
    </div>
  )
}