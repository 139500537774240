import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import {
  country as countrySelector,
  membershipSelector,
  notificationsSelector,
} from '../../../selectors/company';
import { closeModal, openModal } from '../../../reducers/modals';
import Typography from '../../common/Typography';
import Button from '../../common/Button';
import { useCallback, useEffect, useMemo } from 'react';
import { COUNTRIES } from '../../../utils/enums/countries';
import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { APIGraphqlSelector } from '../../../selectors/app';
import { notificationsTransformer } from '../../notifications/utils';
import PosDocument from '../../svg/illustrations/PosDocument';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

function ActivateFePosMexico() {
  const dispatch = useDispatch();
  const { isActive } = useElectronicPayrollStatus();
  const notifications = useSelector(notificationsSelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const COOKIE_NAME = `activate-fe-pos-notification-${country}`;
  const membership = useSelector(membershipSelector);
  const isOpen = useSelector((state) =>
    get(state, 'modals.activateFePosMexico.isOpen', false)
  );

  const availableNotification = useCallback(() => {
    if (notifications?.find((notification) => notification[COOKIE_NAME]))
      return false;
    if (country !== COUNTRIES.MEXICO) return false;
    const membershipPlan = get(membership, 'plan.keyword', '');
    if (
      !membershipPlan ||
      membershipPlan === '' ||
      membershipPlan.includes('demo') ||
      membershipPlan.includes('readonly')
    ) {
      return false;
    }

    return true;
  }, [country, notifications, membership]);

  const closePosElectronicNotification = async () => {
    if (!availableNotification()) return;

    try {
      const response = await APIGraphql(
        graphqlOperation(
          mutations.updateUser,
          notificationsTransformer(COOKIE_NAME)
        )
      );
      if (!!get(response, 'data.updateUser.id', null)) {
        dispatch(setUser(get(response, 'data.updateUser')));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (!availableNotification()) return;
    dispatch(openModal({ modal: 'activateFePosMexico' }));
    closePosElectronicNotification();
  }, []);

  const modalInfo = useMemo(() => {
    if (isActive) {
      return {
        title: I18n.get('', '¡Timbra tus facturas desde Alegra POS! ⚡'),
        description: I18n.get(
          '',
          'Ya puedes crear y timbrar facturas electrónicas sin salir de tu punto de venta'
        ),
        cta: I18n.get('', 'Aprende a timbrarlas'),
      };
    }

    return {
      title: I18n.get('', '¡Habilítate para timbrar tus facturas! 📝'),
      description: I18n.get(
        '',
        'Puedes habilitar tu facturación electrónica y timbrar tus facturas sin salir de tu punto de venta'
      ),
      cta: I18n.get('', 'Conocer como habilitarme'),
    };
  }, [isActive]);

  const handleOpenHelp = () => {
    if (isActive) {
      window.open(
        'https://ayuda.alegra.com/es/crear-facturas-electronicas-pos-a-persona-individual',
        '_blank',
        'noopener noreferrer'
      );
      return;
    }
    window.open(
      'https://ayuda.alegra.com/es/pasos-para-habilitar-tu-facturaci%C3%B3n-electr%C3%B3nica-en-alegra-m%C3%A9xico',
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() =>
        dispatch(closeModal({ modal: 'activateFePosMexico' }))
      }
      includeHeader={false}
      className='modal__small-size modal__mobile-bottom electronic-pos-modal'
    >
      <div className='p-4 electronic-pos-modal-body'>
        <div className='electronic-pos-modal-body-icon'>
          <PosDocument />
        </div>
        <div className='electronic-pos-modal-body-info'>
          <Typography
            type='body-2-bold'
            text={modalInfo.title}
            variant='primary'
          />
          <Typography
            type='body-3-regular'
            text={modalInfo.description}
            variant='tertiary'
          />
        </div>
        <div className='electronic-pos-modal-body-actions'>
          <Button
            variant='ghost'
            onClick={() => [
              dispatch(closeModal({ modal: 'activateFePosMexico' })),
            ]}
          >
            <Typography
              type='label-1'
              variant='secondary'
              text={I18n.get('close', 'Cerrar')}
            />
          </Button>
          <Button
            onClick={() => [
              dispatch(closeModal({ modal: 'activateFePosMexico' })),
              handleOpenHelp(),
            ]}
          >
            <Typography type='label-1' text={modalInfo.cta} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ActivateFePosMexico;
